import { Autocomplete, Chip, TextField, Button } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

const ProjectCampaigns = (props) => {

    const context_data = useContext(ContextData)

    var navigate = useNavigate();

    const [userRightStatus, setUserRightStatus] = useState(false);
    const [loginData, setLoginData] = useState();
    const [employeesData, setEmployeesData] = useState([]);
    const [employeeValue, setEmployeeValue] = useState({ employee_name: "" });
    const [defaultEmployee, setDefaultEmployee] = useState({ employee_name: "" })

    const [ivrData, setIvrData] = useState([])
    const [errorIvrNumber, setErrorIvrNumber] = useState(false)

    const [projectData, setProjectData] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [facebookCampaignId, setFacebookCampaignId] = useState([])
    const [housingCampaignId, setHousingCampaignId] = useState([])
    const [acres99CampaignId, setAcres99CampaignId] = useState([])
    const [ivrNumber, setIvrNumber] = useState([])

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_PROJECT_CAMPAIGN") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getAllProjectData();
            getEmployees(login_data.employee_type);
            // getIvr(login_data.employee_type)
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    function getEmployees(employee_type) {
        context_data.setProcessing(true)
        const getEmployeesUrl = global.config.server_url + "getEmployees";
        axios.post(getEmployeesUrl, { all_data_active: true }).then((getEmployeesResponse) => {
            if (getEmployeesResponse.data) {
                setEmployeesData(getEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    function getIvr(employee_type) {
        context_data.setProcessing(true)
        const getIvrUrl = global.config.server_url + "getIvr";
        axios.post(getIvrUrl, { all_data_list: true }).then((getIvrResponse) => {
            if (getIvrResponse.data) {
                setIvrData(getIvrResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const getCampaigns = async (newValue) => {
        var facebook = newValue.facebook_campaign_id
        var housing = newValue.housing_campaign_id
        var ivr_number = newValue.ivr_number
        var acres99 = newValue.acres99_campaign_id

        if (facebook) {
            var facebook_arr = facebook.split(/[ ,]+/)
            setFacebookCampaignId(facebook_arr)
        }
        else {
            setFacebookCampaignId([])
        }

        if (ivr_number) {
            var ivr_number_arr = ivr_number.split(/[ ,]+/)
            setIvrNumber(ivr_number_arr)
        } else {
            setIvrNumber([])
        }

        if (housing) {
            var housing_arr = housing.split(/[ ,]+/)
            setHousingCampaignId(housing_arr)
        }
        else {
            setHousingCampaignId([])
        }

        if (acres99) {
            var acres99_arr = acres99.split(/[ ,]+/)
            setAcres99CampaignId(acres99_arr)
        }
        else {
            setAcres99CampaignId([])
        }
    }

    const getAllProjectData = async () => {
        const getAllProjectsUrl = global.config.server_url + "getProjects";
        axios.post(getAllProjectsUrl, { all_data_active: true }).then((AllProjectsResponse) => {
            if (AllProjectsResponse.data.length) {
                setProjectData(AllProjectsResponse.data);
            }
        }).catch(err => console.log(err));
    }

    const updateProjectCampaigns = (e) => {
        e.preventDefault()

        if (errorIvrNumber) {
            alert("Clear the errors")
            return
        } else {

            let acres99_campaign_id = acres99CampaignId.toString();
            let housing_campaign_id = housingCampaignId.toString();
            let facebook_campaign_id = facebookCampaignId.toString();
            let ivr_number = ivrNumber.toString();

            let addCampaignData = {
                project_id: selectedProject.builder_project_id,
                project_name: selectedProject.builder_project_name,
                builder_id: selectedProject.builder_id,
                builder_name: selectedProject.builder_name,
                facebook_campaign_id: facebook_campaign_id ? facebook_campaign_id : "",
                housing_campaign_id: housing_campaign_id ? housing_campaign_id : "",
                acres99_campaign_id: acres99_campaign_id ? acres99_campaign_id : "",

                ivr_number: ivr_number ? ivr_number : "",
                default_employee_id: employeeValue.employee_id,
                default_employee_name: employeeValue.employee_name,
                default_employee_mobile: employeeValue.employee_mobile,
                default_employee_email: employeeValue.employee_email,
                login_employee_id: (localStorage.getItem("employee_id")),
                login_employee_name: (localStorage.getItem("employee_name")),
            }

            context_data.setProcessing(true)
            const addCampaignUrl = global.config.server_url + "updateProjectCampaign";
            axios.post(addCampaignUrl, addCampaignData).then((addCampaignResponse) => {
                if (addCampaignResponse.data == true) {
                    alert("Updated");
                    context_data.setProcessing(false);
                    window.location.reload()
                } else {
                    alert("Error in Adding");
                    context_data.setProcessing(false);
                }
            }).catch(err => {
                alert("Error in Adding");
                context_data.setProcessing(false);
                console.log(err);
            });
        }
    }

    const getDefaultEmployee = (newValue) => {
        var employee_id = newValue.default_employee_id;
        if (employee_id) {
            console.log(employee_id)
            var result_arr = employeesData.filter(employee => employee.employee_id.toUpperCase() == employee_id.toUpperCase())
            if (result_arr && result_arr[0]) {
                setEmployeeValue(result_arr[0])
                setDefaultEmployee(result_arr[0])
            } else {
                setDefaultEmployee({ employee_name: "" })
            }
        } else {
            setDefaultEmployee({ employee_name: "" })
        }
    }

    const checkIvrNumber = (e) => {
        setIvrNumber(e.target.value)
        var ivr_number = e.target.value
        if (ivr_number) {
            for (var i = 0; i < projectData.length; i++) {
                if (projectData[i].ivr_number == ivr_number && projectData[i].project_status == "ACTIVE") {
                    setErrorIvrNumber(true)
                    break;
                } else {
                    setErrorIvrNumber(false)
                }
            }
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Project Campaigns</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <h4 className="text-center">Project Campaigns</h4>
                <div className="row ">
                    <div className="col-md-1"></div>
                    <div className="border col-md-10 pb-3">
                        <form className="form-group" onSubmit={(e) => updateProjectCampaigns(e)}>

                            <div className="row my-2 mt-3">
                                {(projectData) && (
                                    <div className="col-12">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={projectData}
                                            onChange={(event, newValue) => {
                                                setSelectedProject(newValue);
                                                getCampaigns(newValue)
                                                getDefaultEmployee(newValue)
                                            }}
                                            getOptionLabel={(option) => ucwords((option.builder_project_name.toString()).toLowerCase()) + " - " + ucwords((option.builder_name.toString()).toLowerCase())}
                                            renderInput={(params) => <TextField {...params} label="Project" name="project" required />}
                                        />
                                    </div>
                                )}
                            </div>
                            {(selectedProject == null) ? ("") : (
                                <>
                                    <div className="row my-2">
                                        <div className="col-12">
                                            Builder Name: <b>{selectedProject.builder_name}</b>
                                            <input type="hidden" name="builder_id" className="form-control" value={selectedProject.builder_id} />
                                            <input type="hidden" name="builder_name" className="form-control" value={selectedProject.builder_name} />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 my-2">
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={[]}
                                                defaultValue={facebookCampaignId}
                                                freeSolo
                                                onChange={(event, newValue) => {
                                                    setFacebookCampaignId(newValue);
                                                }}
                                                value={facebookCampaignId}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Facebok Campaign ID"
                                                        placeholder="Campaign ID and Press `Enter` Key"
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={[]}
                                                defaultValue={housingCampaignId}
                                                freeSolo
                                                onChange={(event, newValue) => {
                                                    setHousingCampaignId(newValue);
                                                }}
                                                value={housingCampaignId}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Housing Campaign ID"
                                                        placeholder="Campaign ID and Press `Enter` Key"
                                                    />
                                                )}
                                            />

                                        </div>

                                        <div className="col-md-6 my-2">
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={[]}
                                                defaultValue={acres99CampaignId}
                                                freeSolo
                                                onChange={(event, newValue) => {
                                                    setAcres99CampaignId(newValue);
                                                }}
                                                value={acres99CampaignId}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Acres99 Campaign ID"
                                                        placeholder="Campaign ID and Press `Enter` Key"
                                                    />
                                                )}
                                            />
                                        </div>
                                        {/* {ivrData && (
                                            <div className="col-md-6 my-2">
                                                <Autocomplete
                                                    multiple
                                                    id="tags-filled"
                                                    options={ivrData}
                                                    getOptionLabel={(option) => option.ivr.toString()}
                                                    onChange={(event, newValue) => {
                                                        setIvrNumber(newValue);
                                                    }}
                                                    value={ivrNumber}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip variant="outlined" label={option.ivr.toString()} {...getTagProps({ index })} />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="IVR"
                                                            placeholder="You can select multiple IVR Numbers"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )} */}

                                        <div className="col-md-6 my-2">
                                            <TextField error={errorIvrNumber ? true : false} fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="IVR" name="ivr" value={ivrNumber} onChange={checkIvrNumber} />
                                        </div>

                                        {employeesData && (
                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={employeesData}
                                                        onChange={(event, newValue) => { setEmployeeValue(newValue); }}
                                                        defaultValue={defaultEmployee}
                                                        value={employeeValue}
                                                        getOptionLabel={(option) => ucwords((option.employee_name.toString()).toLowerCase())}
                                                        renderInput={(params) => <TextField {...params} label="Default Employee" name="default_employee" required />}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-6 my-2">
                                            <Button type="submit" variant="contained" color="success">Submit</Button>
                                        </div>
                                    </div>


                                </>
                            )}
                        </form>
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>
        </>
    )
}

export default ProjectCampaigns