import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import ucwords from 'ucwords';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import { Autocomplete, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ContextData } from './Components/ContextData';
import { Form } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require("moment")

const LeadEdit = (props) => {

    const context_data = useContext(ContextData)

    var navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    const [employeeRightsArray, setEmployeeRightsArray] = useState([])
    const [leadId, setLeadId] = useState(false)
    const [leadDetails, setLeadDetails] = useState(false)
    const [whatsappCheckbox, setWhatsappCheckbox] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                setEmployeeRightsArray(employee_rights_arr)
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_LEAD") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(employee_rights_arr)
            if (isAvailable != true) { navigate("/noAccess") }
            getProjectList(login_data.employee_type);

            var lead_id = searchParams.get("id");

            setLeadId(lead_id)
            getLeadDetails(lead_id, login_data.employee_type);

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    const maskMobile = (value) => {
        var start = (value.slice(0, 6)); // "01"
        var end = (value.slice(-4)); // "3456"
        let regex = /\d/g;
        let result = end.replace(/./g, "*");
        return start + result
    }

    const getLeadDetails = async (id = leadId, employee_type = loginData.employee_type) => {
        if (employee_type == "MASTERUSER") {
            context_data.setProcessing(true)
            const getLeadsListUrl = global.config.server_url + "getLeads";
            await axios.post(getLeadsListUrl, { id: id, employee_type: employee_type }).then((getLeadsListResponse) => {
                if (getLeadsListResponse.data) {
                    context_data.setProcessing(false)
                    setLeadDetails(getLeadsListResponse.data);
                    if (getLeadsListResponse.data.mobile === getLeadsListResponse.data.whatsapp) { setWhatsappCheckbox(true) } else { setWhatsappCheckbox(false) }
                } else {
                    context_data.setProcessing(false)
                    console.log(getLeadsListResponse)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        } else if (employee_type == "EMPLOYEE") {
            context_data.setProcessing(true)
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getLeadsListUrl = global.config.server_url + "getLeads";
            await axios.post(getLeadsListUrl, { id: id, employee_type: employee_type, login_employee_id: login_employee_id }).then((getLeadsListResponse) => {
                if (getLeadsListResponse.data) {
                    context_data.setProcessing(false)
                    setLeadDetails(getLeadsListResponse.data);
                    if (getLeadsListResponse.data.mobile === getLeadsListResponse.data.whatsapp) { setWhatsappCheckbox(true) } else { setWhatsappCheckbox(false) }
                } else {
                    context_data.setProcessing(false)
                    console.log(getLeadsListResponse)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        }
    }

    const [selectedProject, setSelectedProject] = useState(false);
    const [employeeValue, setEmployeeValue] = useState({ employee_name: "" });
    const [propertyTypeValue, setPropertyTypeValue] = useState(false);
    const [occupationValue, setOccupationValue] = useState(false);

    const [isPendingProject, setIsPendingProject] = useState(true);
    const [projectData, setProjectData] = useState(null);
    const [dateDOBvalue, setDateDOBvalue] = useState(null)


    const getProjectList = async (employee_type) => {
        if (employee_type == "MASTERUSER") {
            const getProjectListUrl = global.config.server_url + "getProjects";
            await axios.post(getProjectListUrl, { all_data: true }).then((projectListResponse) => {
                setProjectData(null);
                if (projectListResponse.data.length > 0) {
                    setProjectData(projectListResponse.data);
                    setIsPendingProject(false);
                } else {
                    setProjectData(false);
                }
            }).catch(err => { console.log(err); setProjectData(false); });
        } else if (employee_type == "EMPLOYEE") {
            const getProjectListUrl = global.config.server_url + "getProjectsFromProjectEmployees";
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            await axios.post(getProjectListUrl, { employee_id: login_employee_id }).then((projectListResponse) => {
                setProjectData(null);
                if (projectListResponse.data.length > 0) {
                    setProjectData(projectListResponse.data);
                    setIsPendingProject(false);
                } else {
                    setProjectData(false);
                }
            }).catch(err => { console.log(err); setProjectData(false); });
        }
    }

    const [employeesData, setEmployeesData] = useState([]);

    const getEmployeesList = async (selected_project) => {
        setEmployeesData(false)
        setEmployeeValue({ employee_name: "" })
        context_data.setProcessing(true)
        if ((localStorage.getItem("employee_type") == "MASTERUSER") && selected_project) {
            var project_id = selected_project.builder_project_id;

            const getEmployeesListUrl = global.config.server_url + "getEmployeesFromProjectEmployees";
            await axios.post(getEmployeesListUrl, { project_id: project_id }).then((employeesListResponse) => {
                if (employeesListResponse.data.length > 0) {
                    setEmployeesData(employeesListResponse.data);
                    getDefaultEmployee(employeesListResponse.data)
                    context_data.setProcessing(false)
                } else {
                    setEmployeesData([]);
                    context_data.setProcessing(false)
                }
            }).catch(err => {
                console.log(err);
                setEmployeesData([]);
                context_data.setProcessing(false)
            });
        } else {
            var emp_data = (JSON.parse(localStorage.getItem("login_data")))
            setEmployeeValue(emp_data)
            context_data.setProcessing(false)
        }
    }

    const getDefaultProject = () => {
        var project_name = leadDetails.project_name;
        if (project_name) {
            var result_arr = projectData.filter(project => project.builder_project_name.toUpperCase() == project_name.toUpperCase())
            if (result_arr && result_arr[0]) {
                getEmployeesList(result_arr[0])
                setSelectedProject(result_arr[0])
                return result_arr[0]
            } else {
                return { builder_project_name: "" }
            }
        } else {
            return { builder_project_name: "" }
        }
    }

    const [defaultEmployee, setDefaultEmployee] = useState({ employee_name: "" })

    const getDefaultEmployee = (employees_data) => {
        console.log(employees_data)
        var employee_id = leadDetails.lead_owner_id;
        if (employee_id) {
            var result_arr = employees_data.filter(employee => employee.employee_id.toUpperCase() == employee_id.toUpperCase())
            if (result_arr && result_arr[0]) {
                setEmployeeValue(result_arr[0])
                setDefaultEmployee(result_arr[0])
            } else {
                setDefaultEmployee({ employee_name: "" })
            }
        } else {
            setDefaultEmployee({ employee_name: "" })
        }
    }

    const getDefaultOccupation = () => {
        setOccupationValue(leadDetails.occupation)
        return leadDetails.occupation
    }

    const getDefaultPropertyType = () => {
        setPropertyTypeValue(leadDetails.property_type)
        return leadDetails.property_type
    }

    const submitEditLead = (e) => {
        e.preventDefault()

        if (selectedProject) {

            context_data.setProcessing(true);

            var id = leadId ? leadId : searchParams.get("id");
            var salutation = e.target.salutation ? e.target.salutation.value : ""
            var name = e.target.name ? e.target.name.value : ""
            var mobile = e.target.mobile ? e.target.mobile.value : ""
            var secondary_mobile = e.target.secondary_mobile ? e.target.secondary_mobile.value : ""
            var email = e.target.email ? e.target.email.value : ""
            var secondary_email = e.target.secondary_email ? e.target.secondary_email.value : ""
            var whatsapp = whatsappCheckbox ? e.target.mobile.value : e.target.whatsapp.value
            var date_of_birth = e.target.date_of_birth ? e.target.date_of_birth.value : ""
            var lead_source = e.target.lead_source ? e.target.lead_source.value : ""
            var builder_id = selectedProject.builder_id;
            var builder_name = selectedProject.builder_name;
            var project_id = selectedProject.builder_project_id;
            var project_name = selectedProject.builder_project_name;
            var project_sale_type = selectedProject.sale_type;
            var occupation = e.target.occupation.value ? e.target.occupation.value : ""
            var company = e.target.company ? e.target.company.value : ""
            var designation = e.target.designation ? e.target.designation.value : ""
            var industry = e.target.industry ? e.target.industry.value : ""

            var income = e.target.income.value ? e.target.income.value : ""
            var min_budget = e.target.min_budget.value ? e.target.min_budget.value : ""
            var max_budget = e.target.max_budget.value ? e.target.max_budget.value : ""
            var location = e.target.location.value ? e.target.location.value : ""
            var possession_duration = e.target.possession_duration.value ? e.target.possession_duration.value : ""
            var property_type = e.target.property_type.value ? e.target.property_type.value : ""
            var plot_size = e.target.plot_size ? e.target.plot_size.value : ""
            var bedroom_preference = e.target.bedroom_preference ? e.target.bedroom_preference.value : ""
            var min_carpet_area = e.target.min_carpet_area ? e.target.min_carpet_area.value : ""
            var max_carpet_area = e.target.max_carpet_area ? e.target.max_carpet_area.value : ""
            var purpose = e.target.purpose.value ? e.target.purpose.value : ""

            var employee_id = employeeValue.employee_id;
            var employee_name = employeeValue.employee_name;
            var employee_mobile = employeeValue.employee_mobile;
            var employee_email = employeeValue.employee_email;

            var leadData = {
                id: id,
                salutation: salutation,
                name: name,
                mobile: mobile,
                secondary_mobile: secondary_mobile,
                email: email,
                secondary_email: secondary_email,
                whatsapp: whatsapp,
                date_of_birth: date_of_birth,
                lead_source: lead_source,
                builder_id: builder_id,
                builder_name: builder_name,
                project_id: project_id,
                project_name: project_name,
                project_sale_type: project_sale_type,
                occupation: occupation,
                company: company,
                designation: designation,
                income: income,
                min_budget: min_budget,
                max_budget: max_budget,
                location: location,
                possession_duration: possession_duration,
                property_type: property_type,
                plot_size: plot_size,
                bedroom_preference: bedroom_preference,
                min_carpet_area: min_carpet_area,
                max_carpet_area: max_carpet_area,
                purpose: purpose,

                lead_owner_id: employee_id,
                lead_owner_name: employee_name,
                lead_owner_mobile: employee_mobile,
                lead_owner_email: employee_email,

                login_employee_id: localStorage.getItem("employee_id") ? localStorage.getItem("employee_id").toUpperCase() : "",
                login_employee_name: localStorage.getItem("employee_name") ? localStorage.getItem("employee_name").toUpperCase() : "",
            }

            const submitUpdateLeadUrl = global.config.server_url + "updateLead";

            axios.post(submitUpdateLeadUrl, leadData).then((submitUpdateLeadResponse) => {
                if (submitUpdateLeadResponse.data.result === true) {
                    alert("Lead Updated");
                    context_data.setPrevPage(location.pathname)
                    navigate({ pathname: "/leadDetails/", search: "id=" + id, state: { id: id } })
                    context_data.setProcessing(false);
                } else {
                    if (typeof submitUpdateLeadResponse.data == "object") {
                        alert("Lead is assigned to " + submitUpdateLeadResponse.data[1] + " : " + submitUpdateLeadResponse.data[2]);
                        context_data.setProcessing(false);
                    } else {
                        alert("Error in Updating Lead");
                        context_data.setProcessing(false);
                    }
                }
            }).catch(err => {
                console.log(err); alert("There was a error."); context_data.setProcessing(false);
            });
        } else {
            alert("Project Not Selected")
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/allLeads")}>Leads</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Lead</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 className='text-center'>Edit Basic Lead Profile</h3>
                    </div>
                </div>
                {leadDetails && (
                    <form onSubmit={submitEditLead}>
                        <div className="row ">
                            <div className="col-md-6">
                                <fieldset className='fieldset_info'>
                                    <legend className='fieldset_info_legend'>Basic Information</legend>
                                    <div className="row">

                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={["Mr", "Ms", "Mrs", "Dr", "Prof", "Col"]}
                                                    defaultValue={leadDetails.salutation ? leadDetails.salutation : ""}
                                                    renderInput={(params) => <TextField {...params} label="Salutation" name="salutation" required />}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth label="Name" name="name" required defaultValue={leadDetails.name ? leadDetails.name : ""} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}', readOnly: true, }} label="Mobile" required value={leadDetails.mobile ? maskMobile(leadDetails.mobile) : ""} />
                                                <input type="hidden" className='d-none' style={{ display: "none" }} name="mobile" id="" value={leadDetails.mobile ? leadDetails.mobile : ""} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="Secondary Mobile" name="secondary_mobile" defaultValue={leadDetails.secondary_mobile ? leadDetails.secondary_mobile : ""} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth label="Email" name="email" defaultValue={leadDetails.email ? leadDetails.email : ""} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth label="Secondary Email" name="secondary_email" defaultValue={leadDetails.secondary_email ? leadDetails.secondary_email : ""} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <Form.Check type="switch" id="custom-switch" label="Whatsapp" checked={whatsappCheckbox ? "checked" : ""} onChange={(e) => setWhatsappCheckbox(!whatsappCheckbox)} />
                                            <span>{whatsappCheckbox ? "Same as Mobile" : "Enter Whatsapp Number"}</span>
                                        </div>
                                        {whatsappCheckbox ? "" :
                                            <div className="col-md-6 my-2">
                                                <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="Whatsapp" name="whatsapp" defaultValue={leadDetails.whatsapp ? leadDetails.whatsapp : ""} required />
                                            </div>
                                        }

                                        {/* <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="Whatsapp" name="whatsapp" defaultValue={leadDetails.whatsapp ? leadDetails.whatsapp : ""} />
                                            </div>
                                        </div> */}
                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack>
                                                        <MobileDatePicker
                                                            label="Date of Birth"
                                                            value={dateDOBvalue}
                                                            inputFormat="dd-MMM-yyyy"
                                                            onChange={(newValue) => { setDateDOBvalue(newValue); }}
                                                            renderInput={(params) => <TextField {...params} name="date_of_birth" />}
                                                        />
                                                    </Stack>
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                        {(loginData.employee_type == "MASTERUSER" || (loginData.employee_type == "EMPLOYEE" && (leadDetails.first_source_of_enquiry == null || leadDetails.first_source_of_enquiry == ""))) && employeesData && (<>
                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={global.config.lead_source_list}
                                                        defaultValue={leadDetails.first_source_of_enquiry ? ucwords(leadDetails.first_source_of_enquiry.toLowerCase()) : ""}
                                                        renderInput={(params) => <TextField {...params} label="Lead Source" name="lead_source" required />}
                                                    />
                                                </div>
                                            </div>
                                        </>)}

                                        <div className="col-md-6 my-2">
                                            {projectData && (<>
                                                <div className="input_field">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={projectData}
                                                        onChange={(event, newValue) => {
                                                            setSelectedProject(newValue);
                                                            setDefaultEmployee({ employee_name: "" })
                                                            setEmployeeValue({ employee_name: "" })

                                                            getEmployeesList(newValue);
                                                        }}
                                                        defaultValue={getDefaultProject}
                                                        getOptionLabel={(option) => ucwords((option.builder_project_name.toString()).toLowerCase())}
                                                        renderInput={(params) => <TextField {...params} label="Project" name="project" required />}
                                                    />
                                                </div>
                                            </>)}
                                        </div>

                                        <div className="col-md-6 my-2">
                                            {(loginData.employee_type == "MASTERUSER" || employeeRightsArray.includes("REASSIGN_LEADS")) && employeesData && (<>
                                                <div className="input_field">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={employeesData}
                                                        onChange={(event, newValue) => { setEmployeeValue(newValue); }}
                                                        defaultValue={defaultEmployee}
                                                        value={employeeValue}
                                                        getOptionLabel={(option) => ucwords((option.employee_name.toString()).toLowerCase())}
                                                        renderInput={(params) => <TextField {...params} label="Employee" name="employee" required />}
                                                    />
                                                </div>
                                            </>)}
                                        </div>

                                    </div>
                                </fieldset>

                            </div>
                            <div className="col-md-6">
                                <fieldset className='fieldset_info'>
                                    <legend className='fieldset_info_legend'>Additional Information</legend>

                                    <div className="row">

                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    onChange={(event, newValue) => { setOccupationValue(newValue); }}
                                                    options={["Retired", "Salaried", "Business / Self Employed"]}
                                                    defaultValue={getDefaultOccupation}
                                                    renderInput={(params) => <TextField {...params} label="Occupation" name="occupation" />}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Income" name="income" defaultValue={leadDetails.income ? leadDetails.income : ""} />
                                            </div>
                                        </div>

                                        {occupationValue && occupationValue == 'Salaried' && <>
                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Company" name="company" defaultValue={leadDetails.company ? leadDetails.company : ""} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Designation" name="designation" defaultValue={leadDetails.designation ? leadDetails.designation : ""} />
                                                </div>
                                            </div>
                                        </>}

                                        {occupationValue && occupationValue == 'Business / Self Employed' && <>
                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Company" name="company" defaultValue={leadDetails.company ? leadDetails.company : ""} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Industry" name="industry" defaultValue={leadDetails.industry ? leadDetails.industry : ""} />
                                                </div>
                                            </div>
                                        </>}

                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Min Budget" name="min_budget" defaultValue={leadDetails.min_budget ? leadDetails.min_budget : ""} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Max Budget" name="max_budget" defaultValue={leadDetails.max_budget ? leadDetails.max_budget : ""} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth label="Location" name="location" defaultValue={leadDetails.location ? leadDetails.location : ""} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]{2}', min: 0 }} label="Possession Duration (In Months)" name="possession_duration" defaultValue={leadDetails.possession_duration ? leadDetails.possession_duration : ""} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={["Gated Community Plots", "Individual Plots", "Apartment", "Villa", "Independent House", "Row Houses"]}
                                                    defaultValue={getDefaultPropertyType}
                                                    onChange={(event, newValue) => { setPropertyTypeValue(newValue); }}
                                                    renderInput={(params) => <TextField {...params} label="Preferred Property Type" name="property_type" />}
                                                />
                                            </div>
                                        </div>

                                        {(propertyTypeValue == "Gated Community Plots" || propertyTypeValue == "Individual Plots") ? (<>
                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Plot Size (in sq ft)" name="plot_size" defaultValue={leadDetails.plot_size ? leadDetails.plot_size : ""} />
                                                </div>
                                            </div>
                                        </>) : ""}

                                        {(propertyTypeValue == "Apartment" || propertyTypeValue == "Villa" || propertyTypeValue == "Independent House" || propertyTypeValue == "Row Houses") ? (<>
                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Bedroom Preference" name="bedroom_preference" defaultValue={leadDetails.bedroom_preference ? leadDetails.bedroom_preference : ""} />
                                                </div>
                                            </div>
                                        </>) : ""}

                                        {(propertyTypeValue == "Apartment") ? (<>
                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Min Carpet Area" name="min_carpet_area" defaultValue={leadDetails.min_carpet_area ? leadDetails.min_carpet_area : ""} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 my-2">
                                                <div className="input_field">
                                                    <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Max Carpet Area" name="max_carpet_area" defaultValue={leadDetails.max_carpet_area ? leadDetails.max_carpet_area : ""} />
                                                </div>
                                            </div>
                                        </>) : ""}

                                        <div className="col-md-6 my-2">
                                            <div className="input_field">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    defaultValue={leadDetails.purpose ? leadDetails.purpose : ""}
                                                    options={["End Use", "Investment"]}
                                                    renderInput={(params) => <TextField {...params} label="Purpose" name="purpose" />}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </fieldset>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-12 my-2">
                                <Button type="submit" variant="contained" color="success">Submit</Button>
                            </div>
                        </div>

                    </form >
                )}
            </div >
        </>
    )
}

export default LeadEdit