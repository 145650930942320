import React from 'react'
import { useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';

import Box from "@mui/material/Box";
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HomeIcon from '@mui/icons-material/Home';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LogoutIcon from '@mui/icons-material/Logout';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import GroupIcon from '@mui/icons-material/Group';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CloudIcon from '@mui/icons-material/Cloud';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import WebIcon from '@mui/icons-material/Web';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

import { useLocation, useNavigate } from 'react-router-dom'
import { Modal, Nav, NavDropdown } from 'react-bootstrap';
import axios from 'axios';
import { Autocomplete, TextField } from '@mui/material';
import ucwords from 'ucwords';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: "#210039",
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DesktopDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function DrawerComponents(params) {
    var location = useLocation();
    var navigate = useNavigate();

    const [loginData, setLoginData] = useState(false)
    const [userRightStatus, setUserRightStatus] = useState(false)
    const [userRightsRegex, setUserRightsRegex] = useState(false);
    const [activeKey, setActiveKey] = useState("side_bar_home")

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
                getEmployees("MASTERUSER")
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                setUserRightsRegex(regex)
            }
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }

    }, [])

    const [modalLoginToEmployee, setModalLoginToEmployee] = useState(false)
    const loginToEmployeeModalShow = () => { setModalLoginToEmployee(true) }
    const loginToEmployeeModalClose = () => { setModalLoginToEmployee(false) }

    const [employeesData, setEmployeesData] = useState(false)
    const [employeeValue, setEmployeeValue] = useState({ employee_name: "" });

    function getEmployees(employee_type) {
        const getEmployeesUrl = global.config.server_url + "getEmployees";
        axios.post(getEmployeesUrl, { all_data_active: true }).then((getEmployeesResponse) => {
            if (getEmployeesResponse.data) {
                setEmployeesData(getEmployeesResponse.data);
            } else {
                console.log("error in getting data")
            }
        }).catch(err => { console.log(err); });
    }

    const loginToEmployee = (e) => {
        e.preventDefault()

        localStorage.setItem('master_login_data', localStorage.getItem("login_data"));

        localStorage.setItem('employee_id', employeeValue['employee_id']);
        localStorage.setItem('employee_name', employeeValue['employee_name']);
        localStorage.setItem('employee_mobile', employeeValue['employee_mobile']);
        localStorage.setItem('employee_email', employeeValue['employee_email']);
        localStorage.setItem('employee_type', employeeValue['employee_type']);
        localStorage.setItem('login_data', JSON.stringify(employeeValue));

        navigate({ pathname: "/" });
    }


    const [modalConfirmLogout, setModalConfirmLogout] = useState(false)
    const modalConfirmLogoutOpen = () => { setModalConfirmLogout(true); }
    const modalConfirmLogoutClose = () => { setModalConfirmLogout(false); }

    const logout = () => {
        if (localStorage.getItem("master_login_data")) {
            try {
                var master_user_data = JSON.parse(localStorage.getItem("master_login_data"))
                localStorage.setItem('login_data', localStorage.getItem("master_login_data"));
                localStorage.setItem('employee_id', master_user_data.employee_id);
                localStorage.setItem('employee_name', master_user_data.employee_name);
                localStorage.setItem('employee_mobile', master_user_data.employee_mobile);
                localStorage.setItem('employee_email', master_user_data.employee_email);
                localStorage.setItem('employee_type', master_user_data.employee_type);
                localStorage.setItem('master_login_data', "");
                navigate({ pathname: "/" });
            } catch (error) {
                localStorage.removeItem('master_login_data');
                localStorage.removeItem('employee_userid');
                localStorage.removeItem('employee_type');
                localStorage.removeItem('employee_name');
                localStorage.removeItem('login_data');
                localStorage.clear()
                navigate({ pathname: "/logout" });
            }
        } else {
            localStorage.removeItem('master_login_data');
            localStorage.removeItem('employee_userid');
            localStorage.removeItem('employee_type');
            localStorage.removeItem('employee_name');
            localStorage.removeItem('login_data');
            localStorage.clear()
            navigate({ pathname: "/logout" });
        }

    }

    const sideDrawerMouseEnter = () => {
        params.setOpen(true)
    }

    const sideDrawerMouseLeave = () => {
        if (params.sideDrawerState) {
            params.setOpen(true)
        } else {
            params.setOpen(false)
        }
    }

    return <>

        <Modal
            show={modalConfirmLogout}
            onHide={() => modalConfirmLogoutClose()}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Are you sure you need to Logout</Modal.Title>
            </Modal.Header>
            <form className="form-group" onSubmit={(e) => { logout(e) }}>
                <Modal.Footer>
                    <input type="submit" className="btn btn-primary" value="Logout" />
                    <input type="button" className="btn btn-danger" id="" onClick={() => modalConfirmLogoutClose()} value="Cancel" />
                </Modal.Footer>
            </form>
        </Modal>

        <Modal
            show={modalLoginToEmployee}
            onHide={() => loginToEmployeeModalClose()}
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Login To Employee</Modal.Title>
            </Modal.Header>
            <form className="form-group" onSubmit={(e) => { loginToEmployee(e) }}>
                <Modal.Body>
                    <div>
                        {employeesData && (
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={employeesData}
                                onChange={(event, newValue) => { setEmployeeValue(newValue); }}
                                getOptionLabel={(option) => ucwords((option.employee_name.toString()).toLowerCase())}
                                renderInput={(params) => <TextField {...params} label="Employee" name="employee" required />}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <input type="submit" className="btn btn-primary" value="Login" />
                    <input type="button" className="btn btn-danger" id="" onClick={() => loginToEmployeeModalClose()} value="Cancel" />
                </Modal.Footer>
            </form>
        </Modal>

        <Divider />
        <List onMouseEnter={sideDrawerMouseEnter} onMouseLeave={sideDrawerMouseLeave}>
            <ListItemButton onClick={() => params.navigate("/home")} key={"home"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Home"} sx={{ opacity: params.open ? 1 : 0 }} />
            </ListItemButton>

            {(userRightsRegex && userRightsRegex.test("ADD_LEAD") || loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={() => params.navigate("/allLeads")} key={"allLeads"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary={"All Leads"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)}


            {(userRightsRegex && userRightsRegex.test("ADD_BUILDER") || loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={() => params.navigate("/allBuilders")} key={"allBuilders"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Builders"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)}

            {(userRightsRegex && userRightsRegex.test("ADD_PROJECT") || loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={() => params.navigate("/allProjects")} key={"allProjects"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <AutoAwesomeMotionIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Projects"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)}

            {(userRightsRegex && userRightsRegex.test("ADD_EMPLOYEE") || loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={() => params.navigate("/allEmployees")} key={"allEmployees"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Employees"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)}

            {(userRightsRegex && userRightsRegex.test("ASSIGN_EMPLOYEE") || loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={() => params.navigate("/assignEmployee")} key={"assignEmployee"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <HowToRegIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Assign Employee"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)}

            {(userRightsRegex && userRightsRegex.test("ADD_PROJECT_CAMPAIGN") || loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={() => params.navigate("/websiteApi")} key={"websiteApi"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <WebIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Website API"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)}

            {(userRightsRegex && userRightsRegex.test("ADD_PROJECT_CAMPAIGN") || loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={() => params.navigate("/projectCampaigns")} key={"projectCampaigns"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <CloudIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Project Campaigns"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)}

            {(userRightsRegex && userRightsRegex.test("ADD_PROJECT_CAMPAIGN") || loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={() => params.navigate("/campaignAccounts")} key={"campaignAccounts"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <CampaignIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Campaign Accounts"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)}

            {(loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={loginToEmployeeModalShow} key={"loginToEmployeeModalShow"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <ContactPageIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Login to Employee"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)}

            {/* {(loginData.employee_type == "MASTERUSER") && (<>
                <Divider />
                <ListItemButton onClick={() => params.navigate("/uploadLeadsExcel")} key={"uploadLeadsExcel"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                    <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                        <DriveFolderUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Upload Leads Excel"} sx={{ opacity: params.open ? 1 : 0 }} />
                </ListItemButton>
            </>)} */}

            <Divider />
            {/* <ListItemButton onClick={() => params.navigate("/logout")} key={"logout"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} > */}
            <ListItemButton onClick={() => setModalConfirmLogout(true)} key={"logout"} sx={{ minHeight: 48, justifyContent: params.open ? 'initial' : 'center', px: 2.5, }} >
                <ListItemIcon sx={{ minWidth: 0, mr: params.open ? 3 : 'auto', justifyContent: 'center', }} >
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} sx={{ opacity: params.open ? 1 : 0 }} />
            </ListItemButton>

        </List>
    </>
}

function MainAppBar(params) {
    return <>
        <CssBaseline />
        <AppBar position="fixed"
            //  open={params.open}
            sx={{
                zIndex: theme => theme.zIndex.drawer + 1,
                transition: theme => theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                ...(params.open && {
                    ml: { sm: `${drawerWidth}px` },
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    transition: theme => theme.transitions.create(['width', 'margin'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                })
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={params.handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 1,
                        ...(params.open && { display: "none" }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Box
                    component="img"
                    className='navbar_logo'
                    alt="Pool."
                    src={"/assets/images/pool_logo.png"}
                    sx={{
                        filter: "invert()",
                        ...(params.open && { display: { sm: "none", xs: "block" } }),
                    }}
                />
                {/* <span className='ms-auto'>{localStorage.getItem("employee_name")}</span> */}
                <Nav className='ms-auto'>
                    <NavDropdown
                        title={localStorage.getItem("employee_name")}
                        menuVariant="dark"
                        className='nav_emp_name'
                    >
                        <NavDropdown.Item onClick={() => params.navigate("/changePassword")}>Change Password</NavDropdown.Item>
                        {/* <NavDropdown.Item onClick={() => params.navigate("/logout")}>Logout</NavDropdown.Item> */}
                    </NavDropdown>
                </Nav>
            </Toolbar>

        </AppBar>
    </>
}

function DesktopSideDrawer(params) {
    return <>
        <DesktopDrawer variant="permanent" open={params.open}
            sx={{
                display: { xs: "none", sm: "block" },
            }}
        >
            <DrawerHeader>
                <Box
                    component="img"
                    className='navbar_logo'
                    alt="Pool."
                    src={"/assets/images/pool_logo.png"}
                    sx={{
                        marginRight: "auto",
                        ...(params.open && { display: { xs: "none", sm: "block" } }),
                    }}
                />

                <IconButton onClick={params.handleDrawerClose}>
                    {params.theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <DrawerComponents navigate={params.navigate} open={params.open} setOpen={params.setOpen} sideDrawerState={params.sideDrawerState} />
        </DesktopDrawer>
    </>
}

function MobileSideDrawer(params) {
    return <>
        <Drawer
            sx={{
                display: { sm: "none", xs: "block" },
            }}
            anchor={"left"}
            open={params.open}
            onClose={params.handleDrawerClose}
        >
            <DrawerHeader>
                <IconButton onClick={params.handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <DrawerComponents navigate={params.navigate} open={params.open} />
        </Drawer>
    </>;
}

function SideDrawer(props) {
    var navigate = useNavigate();

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [sideDrawerState, setSideDrawerState] = useState(false);

    const handleDrawerOpen = () => {
        setSideDrawerState(true)
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setSideDrawerState(false)
        setOpen(false);
    };


    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <MainAppBar open={open} handleDrawerOpen={handleDrawerOpen} navigate={navigate} />
                <DesktopSideDrawer theme={theme} open={open} sideDrawerState={sideDrawerState} setOpen={setOpen} handleDrawerClose={handleDrawerClose} navigate={navigate} />
                <MobileSideDrawer theme={theme} open={open} sideDrawerState={sideDrawerState} setOpen={setOpen} handleDrawerClose={handleDrawerClose} navigate={navigate} />
                <Box className="main_component" component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    {props.pageData}
                </Box>
            </Box>
        </>
    )
}

export default SideDrawer