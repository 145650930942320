import React, { useContext } from 'react'
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require('moment')

const EmployeesList = (props) => {
    const context_data = useContext(ContextData)
    var navigate = useNavigate();

    const [employeesData, setEmployeesData] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    var gridRef = useRef();

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_EMPLOYEE") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getEmployees(login_data.employee_type);

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    function getEmployees(employee_type) {
        context_data.setProcessing(true)
        const getEmployeesUrl = global.config.server_url + "getEmployees";
        axios.post(getEmployeesUrl, { all_data_list: true }).then((getEmployeesResponse) => {
            if (getEmployeesResponse.data) {
                setEmployeesData(getEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            sortable: true,
            autoHeight: true,
        }
    )

    const ucwords_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = params.data[column_name] ? ucwords(params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
        return newValue
    }

    const btnRenderer = (params) => {
        let edit_btn = document.createElement("button");
        edit_btn.innerHTML = "Edit";
        edit_btn.classList = "table_list_btn btn btn-info"
        edit_btn.onclick = function () {
            navigate({ pathname: "/editEmployee/", search: "id=" + params.data.id, state: { id: params.data.id } })
        };
        return edit_btn;
    }

    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    const lowercase_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = params.data[column_name] ? (params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
        return newValue
    }

    const uppercase_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = params.data[column_name] ? (params.data[column_name].toUpperCase()).replaceAll('_', ' ') : "-"
        return newValue
    }

    const datetime_value = (params) => {
        var column_name = (params.column.colId)
        if (moment(params.data[column_name]).isValid()) {
            return moment(params.data[column_name]).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(params.data[column_name], "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            return moment(params.data[column_name], "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(params.data[column_name], "YYYY-MM-DD H:mm:ss", true).isValid()) {
            return moment(params.data[column_name], "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            return params.data[column_name]
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Employees</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <input className='btn btn-primary' type="button" value="Add New Employee" onClick={() => navigate("/addEmployee")} />
                    </div>
                    <div className="col-md-4">
                        <h3 className='text-center'>All Employees</h3>
                    </div>
                    <div className="col-md-4">
                        <input className='form-control' type="text" id="filter-text-box" placeholder="Search..." onInput={onFilterTextBoxChanged} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {(employeesData == false || employeesData == null) ? (<div><h3 className="text-center m-5">No Data Available</h3></div>) : (
                            <>
                                <div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
                                    <AgGridReact
                                        defaultColDef={defaultColDef}
                                        rowData={employeesData}
                                        pagination={true}
                                        paginationAutoPageSize={true}
                                        animateRows={true}
                                        ref={gridRef}
                                        onGridReady={() => autoSizeAll(false)}
                                        onFirstDataRendered={() => autoSizeAll(false)}
                                    >
                                        <AgGridColumn width="100" headerName="Sl No" valueGetter={"node.rowIndex+1"} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Employee ID" field="employee_id" ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Name" field="employee_name" valueGetter={ucwords_value} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Designation" field="employee_designation" valueGetter={ucwords_value} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Mobile" field="employee_mobile" valueGetter={ucwords_value}></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Email" field="employee_email" valueGetter={lowercase_value} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Join Date" field="employee_joindate" valueGetter={uppercase_value} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Status" field="employee_status" valueGetter={ucwords_value} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Created By" field="create_name" valueGetter={ucwords_value}></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Created On" field="create_datetime" valueGetter={datetime_value}></AgGridColumn>
                                        <AgGridColumn headerName="Actions" cellRenderer={btnRenderer} ></AgGridColumn>
                                    </AgGridReact>
                                </div>
                            </>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeesList