import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { ContextData } from './ContextData';

const CreateWhatsapp = (props) => {

    let [searchParams, setSearchParams] = useSearchParams();
    const context_data = useContext(ContextData)

    useEffect(() => {
        var lead_id = searchParams.get("id");
        setLeadId(lead_id)
    }, [])

    const [leadId, setLeadId] = useState(false)

    const submitWhatsapp = async (e) => {
        e.preventDefault();
        const addWhatsappUrl = global.config.server_url + "addWhatsapp";
        let notesData = "Clicked on Whatsapp Button";
        notesData = notesData.replaceAll("'", "");
        notesData = notesData.replaceAll('"', '');

        const addWhatsappData = {
            notes_data: notesData,
            login_employee_id: localStorage.getItem("employee_id"),
            login_employee_name: localStorage.getItem("employee_name"),
            id: (leadId != undefined || leadId != null) ? leadId : searchParams.get("id")
        }

        context_data.setProcessing(true);

        await axios.post(addWhatsappUrl, addWhatsappData).then((response) => {
            if (response.data == true) {
                context_data.setProcessing(false);
                props.getLeadDetails();
            } else {
                alert("Error in Adding Data");
                context_data.setProcessing(false);
            }
        }).catch(err => {
            console.log(err);
            alert("Error in Adding Data");
            context_data.setProcessing(false);
        });
        openInNewTabWhatsapp((props.leadDetails.whatsapp) ? (props.leadDetails.whatsapp) : (props.leadDetails.mobile))
    }

    const openInNewTabWhatsapp = (number) => {
        var url = "https://api.whatsapp.com/send?phone=+91" + number + "&text=Hi%20Click%20button%20to%20start%20Chat."
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <input type="button" className="m-2" value="Whatsapp"  onClick={(e) => { submitWhatsapp(e); }} />
        </>
    )
}

export default CreateWhatsapp