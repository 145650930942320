import React, { useContext, useEffect } from 'react'

import TextField from '@mui/material/TextField';
import { FormControl, IconButton, Input, InputAdornment, InputLabel, Stack } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { Container, Navbar } from 'react-bootstrap';
import { ContextData } from './Components/ContextData';

const Login = () => {

    const context_data = useContext(ContextData)

    var navigate = useNavigate();

    useEffect(() => {
        const checkLogin = () => {
            let checkSessionUserType = localStorage.getItem('login_data');
            if (checkSessionUserType) {
                navigate({ pathname: "/home" });
            }
        }
        checkLogin();
    }, [])

    const [values, setValues] = React.useState({ amount: '', password: '', weight: '', weightRange: '', showPassword: false, });

    const handleChange = (prop) => (event) => { setValues({ ...values, [prop]: event.target.value }); };

    const handleClickShowPassword = () => { setValues({ ...values, showPassword: !values.showPassword, }); };

    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    const handleForgotPasswordShow = () => { }

    const submitLogin = (e) => {
        e.preventDefault();
        context_data.setProcessing(true);

        const employee_id = e.target.employee_id.value;
        const employee_password = e.target.employee_password.value;

        const url = global.config.server_url + "login";
        axios.post(url, { employee_id: employee_id, employee_password: employee_password }).then((response) => {
            if (response.data == false) {
                context_data.setProcessing(false);
                alert("Invalid Login");
            } else if (response.data == "inactive") {
                alert("Your account is INACTIVE. Please Contact Admin");
                context_data.setProcessing(false);
            } else if (response.data && response.data['employee_type'].toLowerCase() === "admin" || response.data['employee_type'].toLowerCase() === "masteruser" || response.data['employee_type'].toLowerCase() === "employee") {
                localStorage.setItem('employee_id', response.data['employee_id']);
                localStorage.setItem('employee_name', response.data['employee_name']);
                localStorage.setItem('employee_mobile', response.data['employee_mobile']);
                localStorage.setItem('employee_email', response.data['employee_email']);
                localStorage.setItem('employee_type', response.data['employee_type']);
                localStorage.setItem('login_data', JSON.stringify(response.data));
                context_data.setProcessing(false);
                navigate("/home", { state: { login_data: JSON.stringify(response.data) } });
            } else {
                context_data.setProcessing(false);
                alert("Invalid Login");
            }
        }).catch(err => { context_data.setProcessing(false); alert("Error in Login"); console.log(err); });

    }

    const openInNewTab = (url) => {
        if (url) {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }


    return (
        <>
            <div style={{ position: "relative" }}>

                <section className="section_login">
                    <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-md-6 div_img_login">
                                <img className="" id="img_login" src={"/assets/images/login_img1.png"} />
                            </div>
                            <div className="col-md-6 mx-auto">
                                <div className="card login_card ">
                                    <img className="card-img-top mx-auto" style={{ width: "100px", height: "100px" }} src={"/assets/images/login_img.png"} />
                                    <div className="card-body">
                                        <h1 className="card-title text-center">LOGIN</h1>
                                        <form className="form-group" onSubmit={(e) => submitLogin(e)}>
                                            <FormControl style={{ marginBottom: "20px" }} fullWidth variant="standard">
                                                <TextField fullWidth name="employee_id" id="standard-basic" label="Employee ID / Email / Mobile" variant="standard" required />
                                            </FormControl>
                                            <FormControl style={{ marginBottom: "20px" }} fullWidth variant="standard">
                                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                                <Input
                                                    required
                                                    name="employee_password"
                                                    id="standard-adornment-password"
                                                    type={values.showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    onChange={handleChange('password')}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                            <div>
                                                <a className='a_tag_forgot_password' onClick={() => handleForgotPasswordShow()} >Forgot Password</a>

                                            </div>
                                            <div className="inputButtonDet">
                                                <input type="submit" className="mt-3 form-control btn btn-warning border border-dark btnLoginDet" id="" value="Login" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </section >

                <div className="div_login_footer container-fluid">
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <p>Copyright &#169; 2021 Pool / All rights reserved | <span className="cursive_font link_policy" onClick={() => openInNewTab("policy")}>Policy.</span></p>
                        </div>
                        <div className="col-md-6 text-center">
                            <p>Developed by: <span className="cursive_font  odm_link" onClick={() => openInNewTab("https://www.orangedigitalmedia.in/")}>Orange Digital Media</span></p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login