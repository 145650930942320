import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ContextData } from '../Components/ContextData';
import ucwords from 'ucwords';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import { FloatingLabel, Form, Table } from 'react-bootstrap'

var moment = require('moment')

const RemindersSummary = () => {

    var navigate = useNavigate();
    var gridRef = useRef();

    const context_data = useContext(ContextData)

    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);

    const [leadsData, setLeadsData] = useState(false);
    const [employeesData, setEmployeesData] = useState(false);
    const [projectsData, setProjectsData] = useState(false);
    const [buildersData, setBuildersData] = useState(false);

    const [allBuildersData, setAllBuildersData] = useState(false);
    const [allProjectsData, setAllProjectsData] = useState(false);
    const [allLeadsData, setAllLeadsData] = useState(false);
    const [allEmployeesData, setAllEmployeesData] = useState(false);

    const [selectedBuilder, setSelectedBuilder] = useState("")
    const [selectedProject, setSelectedProject] = useState("")
    const [selectedEmployee, setSelectedEmployee] = useState("")

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_BUILDER") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }

            getBuilders(login_data.employee_type);
            getProjects(login_data.employee_type);
            getEmployees(login_data.employee_type);

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    async function getBuilders(employee_type) {
        context_data.setProcessing(true)
        const getBuildersUrl = global.config.server_url + "getBuilders";
        await axios.post(getBuildersUrl, { all_data: true }).then((getBuildersResponse) => {
            if (getBuildersResponse.data) {
                setAllBuildersData(getBuildersResponse.data);
                setBuildersData(getBuildersResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    async function getProjects(employee_type) {
        context_data.setProcessing(true)
        const getProjectsUrl = global.config.server_url + "getProjects";
        await axios.post(getProjectsUrl, { all_data: true }).then((getProjectsResponse) => {
            if (getProjectsResponse.data) {
                setAllProjectsData(getProjectsResponse.data);
                setProjectsData(getProjectsResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    async function getEmployees(employee_type) {
        context_data.setProcessing(true)
        const getEmployeesUrl = global.config.server_url + "getEmployees";
        await axios.post(getEmployeesUrl, { all_data_active: true }).then((getEmployeesResponse) => {
            if (getEmployeesResponse.data) {
                setAllEmployeesData(getEmployeesResponse.data);
                setEmployeesData(getEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    async function getLeadsForReport() {
        var builder_id = document.getElementById('select_builder') ? document.getElementById('select_builder').value : ''
        var project_id = document.getElementById('select_project') ? document.getElementById('select_project').value : ''
        var employee_id = document.getElementById('select_employee') ? document.getElementById('select_employee').value : ''
        console.log(builder_id, project_id, employee_id)
        // context_data.setProcessing(true)
        // const getLeadsForReportUrl = global.config.server_url + "getLeadsForReport";
        // await axios.post(getLeadsForReportUrl, { all_data: true }).then((getLeadsForReportResponse) => {
        //     if (getLeadsForReportResponse.data) {
        //         setAllLeadsData(getLeadsForReportResponse.data);
        //         setLeadsData(getLeadsForReportResponse.data);
        //         context_data.setProcessing(false)
        //         console.log(getLeadsForReportResponse.data)
        //     } else {
        //         alert("error in getting data")
        //         context_data.setProcessing(false)
        //     }
        // }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const handleBuilderChange = (e) => {
        setSelectedBuilder(e.target.value);
        getLeadsForReport()
        var builder_id = e.target.value;
        var projects = allProjectsData.filter(project => project.builder_id == builder_id);
        setProjectsData(projects)
    }

    const handleProjectChange = (e) => {
        setSelectedProject(e.target.value);
        getLeadsForReport()
        var project_id = e.target.value;
        console.log(e.target)
        var builders = allBuildersData.filter(builder => builder.builder_id == project_id);
        // setEmployeesData(employees)
    }

    const handleEmployeeChange = (e) => {
        setSelectedEmployee(e.target.value);

    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">

                    {(buildersData == false || buildersData == null) ? ("No Builder Available") : (
                        <div className="col-md-3">
                            <FloatingLabel controlId="floatingSelect" label="Builder">
                                <Form.Select name="builder" id="select_builder" aria-label="Floating label select example" required
                                    onChange={(e) => { handleBuilderChange(e) }}
                                >
                                    <option value="" selected>Select Builder</option>
                                    {buildersData.map((builder) => <option key={builder.id} value={builder.builder_id}>{ucwords(builder.builder_name.toLowerCase())}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                    )}

                    {(projectsData == false || projectsData == null) ? ("No Project Available") : (
                        <div className="col-md-3">
                            <FloatingLabel controlId="floatingSelect" label="Project">
                                <Form.Select name="project" id="select_project" aria-label="Floating label select example" required
                                    onChange={(e) => { handleProjectChange(e) }}
                                >
                                    <option value="" selected>Select Project</option>
                                    {projectsData.map((project) => <option key={project.id} data_builder_id={project.builder_id} value={project.builder_project_id}>{ucwords(project.builder_project_name.toLowerCase())}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                    )}
                    {(projectsData == false || projectsData == null || employeesData == false || employeesData == null) ? ("No Employee Available") : (
                        <div className="col-md-3">
                            <FloatingLabel controlId="floatingSelect" label="Employee">
                                <Form.Select name="employee" id="select_employee" aria-label="Floating label select example" required
                                    onChange={(e) => { handleEmployeeChange(e) }}
                                >
                                    <option value="" selected>Select Employee</option>
                                    {employeesData.map((employee) => <option key={employee.id} value={employee.employee_id}>{ucwords(employee.employee_name.toLowerCase())}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                    )}

                </div>
            </div>

        </>
    )
}

export default RemindersSummary