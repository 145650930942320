import { Stack, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ucwords from 'ucwords';
import { Button, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ReminderDetails from './ReminderDetails';
import { ContextData } from './ContextData';

var moment = require("moment");

const CancelReminder = (props) => {

    const context_data = useContext(ContextData)

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        var enquiry_id = searchParams.get("id");
        setEnquiryId(enquiry_id)
    }, [])

    const [enquiryId, setEnquiryId] = useState(false)

    const [cancelReason, setCancelReason] = useState("")

    const [cancelReminder, setCancelReminder] = useState(false);
    const handleCancelReminderShow = () => {
        setCancelReason("");
        context_data.setPickerDatetime(context_data.reminderType == "sitevisit" ? moment(new Date()).add((30 - ((moment(new Date())).minute() % 15)), 'm').toDate() : moment(new Date()).add((5 - ((moment(new Date())).minute() % 5)), 'm').toDate())
        setCancelReminder(true);
    }
    const handleCancelReminderClose = () => { setCancelReason(""); setCancelReminder(false); }

    function submitCancelReminder(e) {
        e.preventDefault()
        props.unblockEmployeeWithLead()

        var cancel_reason = e.target.cancel_reason.value;
        var closed_reason = e.target.closed_reason ? e.target.closed_reason.value : "";
        var competitor_name = e.target.competitor_name ? e.target.competitor_name.value : "";

        var notes = e.target.notes ? e.target.notes.value : "";

        notes = notes.replace(/["']/g, "");
        notes = notes.replace(/\s+/g, ' ').trim()

        var reminder_datetime = context_data.pickerDatetime;
        reminder_datetime.setSeconds(0);
        var id = (enquiryId != undefined || enquiryId != null) ? enquiryId : searchParams.get("id");
        var activity = props.nextReminder.activity;

        const cancelReminderUrl = global.config.server_url + "cancelReminder";
        const cancelReminderData = {
            activity: activity,
            cancel_reason: cancel_reason,
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
            id: (enquiryId != undefined || enquiryId != null) ? enquiryId : searchParams.get("id")
        };

        context_data.setProcessing(true);

        if (cancel_reason.toLowerCase() == "closed") {
            const cancelReminderData = {
                cancel_reason: cancel_reason,
                closed_reason: closed_reason,
                competitor_name: competitor_name,
                activity: activity,
                login_employee_id: (localStorage.getItem("employee_id")),
                login_employee_name: (localStorage.getItem("employee_name")),
                id: id
            };

            axios.post(cancelReminderUrl, cancelReminderData).then((response) => {
                if (response.data == true) {
                    context_data.setProcessing(false);
                    handleCancelReminderClose();
                    props.getLeadDetails();
                } else {
                    alert("Error in Cancel Reminder");
                    context_data.setProcessing(false);
                }
            }).catch(err => { console.log(err); alert("Error in Cancel "); context_data.setProcessing(false); });
        } else {
            const addReminderData = {
                notes: notes,
                reminder_datetime: reminder_datetime,
                login_employee_id: (localStorage.getItem("employee_id")),
                login_employee_name: (localStorage.getItem("employee_name")),
                id: id
            };

            var addReminderUrl;

            if (context_data.reminderType == "sitevisit") {
                addReminderUrl = global.config.server_url + "addSiteVisit";
            } else if (context_data.reminderType == "followup") {
                addReminderUrl = global.config.server_url + "addFollowUp";
            }

            axios.post(cancelReminderUrl, cancelReminderData).then((response) => {
                if (response.data == true) {
                    axios.post(addReminderUrl, addReminderData).then((response) => {
                        if (response.data == true) {
                            context_data.setProcessing(false);
                            handleCancelReminderClose();
                            props.getLeadDetails();
                        } else {
                            alert("Error in Creating new Reminder");
                            context_data.setProcessing(false);
                        }
                    }).catch(err => { console.log(err); alert("Error in Adding new Reminder"); context_data.setProcessing(false); });
                } else {
                    alert("Error in Cancel Reminder");
                    context_data.setProcessing(false);
                }
            }).catch(err => { console.log(err); alert("Error in Cancel "); context_data.setProcessing(false); });

        }

    }

    const changeReminderType = (e) => {
        context_data.setReminderType(e.target.value)
        context_data.setPickerDatetime(e.target.value == "sitevisit" ? moment(new Date()).add((30 - ((moment(new Date())).minute() % 15)), 'm').toDate() : moment(new Date()).add((5 - ((moment(new Date())).minute() % 5)), 'm').toDate())
    }

    return (
        <>
            {/* Modal for Cancelling Next Reminder */}
            <Modal
                show={cancelReminder}
                onHide={() => handleCancelReminderShow()}
                keyboard={false}
                centered
            >
                <Modal.Header >
                    <Modal.Title>Cancel {(props.nextReminder) ? ucwords(props.nextReminder.activity.replaceAll("_", " ")) : ""}</Modal.Title>
                </Modal.Header>
                <form className="form-group" onSubmit={(e) => submitCancelReminder(e)} >
                    <Modal.Body>
                        <div>
                            <input type="hidden" id="cancelReminderText" name="cancelReminderText" value={(props.nextReminder) ? (props.nextReminder.activity) : ""} />
                        </div>
                        {(props.nextReminder) ? (
                            <>
                                <Stack spacing={3}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        name='cancel_reason'
                                        label="Reason for Cancelling"
                                        value={cancelReason}
                                        onChange={(e) => setCancelReason(e.target.value)}
                                        required
                                    >
                                        <MenuItem key={2} value="Missed Activity">Missed {ucwords(props.nextReminder.activity)}</MenuItem>
                                        <MenuItem key={3} value="On Leave">On Leave</MenuItem>
                                        <MenuItem key={4} value="Closed">Closed</MenuItem>
                                    </TextField>

                                    {(cancelReason == "Closed") ? (
                                        <>
                                            <TextField
                                                select
                                                name='closed_reason'
                                                label="Closed Reason"
                                                value={props.closedReason}
                                                onChange={(e) => props.setClosedReason(e.target.value)}
                                                required
                                            >
                                                <MenuItem key={1} value="Lost to Competitor">Lost to Competitor</MenuItem>
                                                <MenuItem key={3} value="Plan Drop">Plan Drop</MenuItem>
                                            </TextField>
                                        </>
                                    ) : (cancelReason != "") ? (
                                        <>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={context_data.reminderType}
                                                exclusive
                                                onChange={changeReminderType}
                                                name="reminder_type"
                                            >
                                                <ToggleButton value="followup">Create Followup</ToggleButton>
                                                <ToggleButton value="sitevisit">Create Sitevisit</ToggleButton>
                                            </ToggleButtonGroup>
                                            <ReminderDetails />
                                        </>
                                    ) : ""}
                                    {(cancelReason == "Closed" && props.closedReason == "Lost to Competitor") ? (
                                        <TextField label={"Competitor Name"} name="competitor_name" variant="outlined" required />
                                    ) : ""}

                                </Stack>
                            </>) : ""}
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="submit" className="btn btn-primary" id="" value="Cancel Reminder" />
                        <input type="button" className="btn btn-danger" id="" onClick={() => handleCancelReminderClose()} value="Close" />
                    </Modal.Footer>
                </form>

            </Modal>

            <input type="button" className="leads_list_btn btn btn-warning me-2" value={(props.nextReminder) ? "Cancel " + ucwords(props.nextReminder.activity.replaceAll("_", " ")) : "Cancel"} onClick={() => handleCancelReminderShow()} />
        </>
    )
}

export default CancelReminder