import { Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require('moment');

const CampaignAccounts = () => {

    const context_data = useContext(ContextData)

    var navigate = useNavigate()

    const [loginData, setLoginData] = useState(false)
    const [campaignAccounts, setCampaignAccounts] = useState(false)
    const [errorApiKey, setErrorApiKey] = useState(false)
    const [errorProfileId, setErrorProfileId] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ASSIGN_EMPLOYEE") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            if (isAvailable != true) { navigate("/noAccess") }

            getCampaignAccounts();
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }

    }, [])

    const getCampaignAccounts = async () => {
        context_data.setProcessing(true)
        const getCampaignAccountsUrl = global.config.server_url + "getCampaignAccounts";
        axios.post(getCampaignAccountsUrl, { all_data: true }).then((campaignAccountsResponse) => {
            if (campaignAccountsResponse.data.length > 0) {
                setCampaignAccounts(campaignAccountsResponse.data);
                context_data.setProcessing(false)
            } else {
                setCampaignAccounts(false);
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); setCampaignAccounts(false); context_data.setProcessing(false) });
    }

    const datetime_value = (value) => {
        if (moment(value).isValid()) {
            return moment(value).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            return moment(value, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD H:mm:ss", true).isValid()) {
            return moment(value, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            return value
        }
    }

    const submitAddCampaignAccount = (e) => {
        e.preventDefault()

        if (errorApiKey || errorProfileId) {
            alert("Clear the errors")
            return
        } else {
            var source = e.target.source.value;
            var api_key = e.target.api_key.value;
            var profile_id = e.target.profile_id.value;

            var submitCampaignAccountData = {
                source: source,
                api_key: api_key,
                profile_id: profile_id,

                login_employee_id: (localStorage.getItem("employee_id")),
                login_employee_name: (localStorage.getItem("employee_name")),

            }

            var submitCampaignAccountUrl = global.config.server_url + "addCampaignAccount";
            axios.post(submitCampaignAccountUrl, submitCampaignAccountData).then((response) => {
                if (response.data === true) {
                    alert("Campaign Account Added");
                    context_data.setProcessing(false);
                    e.target.source.value = "";
                    e.target.api_key.value = "";
                    e.target.profile_id.value = "";
                    getCampaignAccounts();

                } else {
                    alert("Error in Adding Campaign Account");
                    context_data.setProcessing(false);
                }
            }).catch(err => { console.log(err); alert("Error in Adding Campaign Account"); context_data.setProcessing(false); });
        }
    }

    const deleteCampaignAccount = (id) => {
        var deleteCampaignAccountUrl = global.config.server_url + "deleteCampaignAccount";
        axios.post(deleteCampaignAccountUrl, { campaign_account_table_id: id }).then((response) => {
            if (response.data === true) {
                alert("Campaign Account Deleted");
                context_data.setProcessing(false);
                getCampaignAccounts();
            } else {
                alert("Error in Delete Campaign Account");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Delete Campaign Account"); context_data.setProcessing(false); });
    }

    const checkApiKey = (e) => {
        var api_key = e.target.value.toLowerCase()
        for (var i = 0; i < campaignAccounts.length; i++) {
            if (campaignAccounts[i].api_key.toLowerCase() == api_key) {
                setErrorApiKey(true)
                break;
            } else {
                setErrorApiKey(false)
            }
        }
    }

    const checkProfileId = (e) => {
        var profile_id = e.target.value.toUpperCase()
        for (var i = 0; i < campaignAccounts.length; i++) {
            if (campaignAccounts[i].profile_id.toUpperCase() === profile_id) {
                setErrorProfileId(true)
                break;
            } else {
                setErrorProfileId(false)
            }
        }
    }

    const maskApiKey = (value) => {
        var start = (value.slice(0, 2)); // "01"
        var middle_value = (value.slice(2, -2));
        let regex = /\d/g;
        let result = middle_value.replace(/./g, "*");
        var end = (value.slice(-2)); // "3456"
        return start + result + end
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Campaign Accounts</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <form onSubmit={submitAddCampaignAccount}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-center">Campaign Accounts</h2>
                        </div>

                        <div className="col-md-4 my-2">
                            <TextField fullWidth label="Source" name="source" required />
                        </div>
                        <div className="col-md-4 my-2">
                            <TextField fullWidth label="API Key" name="api_key" required onChange={checkApiKey} error={errorApiKey} />
                        </div>
                        <div className="col-md-4 my-2">
                            <TextField fullWidth label="Profile ID" name="profile_id" required onChange={checkProfileId} error={errorProfileId} />
                        </div>
                        <div className="col-md-4 my-2">
                            <Button type="submit" variant="contained" color="success">Submit</Button>
                        </div>

                        <div className="col-12">
                            <div className="div_campaign_accounts_list">
                                {(campaignAccounts) ? (
                                    <table id="campaign_accounts_list" className="display table table-striped table-bordered compact" width="100%" >
                                        <thead className="bg-dark text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>Source</th>
                                                <th>API Key</th>
                                                <th>Profile ID</th>
                                                <th>Created by</th>
                                                <th>Created at</th>
                                                <th>Updated by</th>
                                                <th>Updated at</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {campaignAccounts.map((campaignAccount, index) =>
                                                <tr key={campaignAccount.id} >
                                                    <td className="campaign_accounts_list_td">{index + 1}</td>
                                                    <td className="campaign_accounts_list_td">{(campaignAccount.source) ? ucwords(campaignAccount.source.toLowerCase()) : "-"}</td>
                                                    <td className="campaign_accounts_list_td">{(campaignAccount.api_key) ? maskApiKey(campaignAccount.api_key.toLowerCase()) : "-"}</td>
                                                    <td className="campaign_accounts_list_td">{(campaignAccount.profile_id) ? (campaignAccount.profile_id) : "-"}</td>
                                                    <td className="campaign_accounts_list_td">{(campaignAccount.create_name) ? (ucwords(campaignAccount.create_name.toLowerCase())) : "-"}</td>
                                                    <td className="campaign_accounts_list_td">{(campaignAccount.create_datetime) ? datetime_value(campaignAccount.create_datetime) : "-"}</td>
                                                    <td className="campaign_accounts_list_td">{(campaignAccount.update_name) ? (ucwords(campaignAccount.update_name.toLowerCase())) : "-"}</td>
                                                    <td className="campaign_accounts_list_td">{(campaignAccount.update_datetime) ? datetime_value(campaignAccount.update_datetime) : "-"}</td>
                                                    <td className="campaign_accounts_list_td"> <input type="button" className="projects_list_btn btn btn-info m-0 pt-0 pb-0 pl-1 pr-1" onClick={() => deleteCampaignAccount(campaignAccount.id)} value="Delete" />  </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                ) : ""}

                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default CampaignAccounts