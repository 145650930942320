import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { ContextData } from './ContextData';

const CreateCall = (props) => {

	let [searchParams, setSearchParams] = useSearchParams();
	const context_data = useContext(ContextData)
	const [loginData, setLoginData] = useState(false);

	useEffect(() => {
		setLoginData(localStorage.getItem("login_data"))
		var login_data = JSON.parse(localStorage.getItem("login_data"))
		setLoginData(login_data)

		var lead_id = searchParams.get("id");
		setLeadId(lead_id)
	}, [])

	const [leadId, setLeadId] = useState(false)

	const submitCall = async (e) => {
		e.preventDefault();
		blockEmployeeWithLead()

		changeAttendedBy()

		const addCallUrl = global.config.server_url + "addCall";
		let notesData = "Clicked on Call Button";
		notesData = notesData.replaceAll("'", "");
		var id = (leadId != undefined || leadId != null) ? leadId : searchParams.get("id");

		const addCallData = {
			notes_data: notesData,
			login_employee_id: localStorage.getItem("employee_id"),
			login_employee_name: localStorage.getItem("employee_name"),
			id: id
		}

		context_data.setProcessing(true);

		await axios.post(addCallUrl, addCallData).then((response) => {
			if (response.data == true) {
				context_data.setProcessing(false);
				props.getLeadDetails();
			} else {
				alert("Error in Adding Data");
				context_data.setProcessing(false);
			}
		}).catch(err => { console.log(err); alert("Error in Adding Data"); context_data.setProcessing(false); });


		openInNewTabCall(props.leadDetails.mobile)

	}

	const changeAttendedBy = async (lead_id = props.leadDetails.id, lead_details = props.leadDetails) => {
		if (localStorage.getItem("employee_id").toUpperCase() == lead_details.lead_owner_id.toUpperCase()) {
			const changeAttendedByUrl = global.config.server_url + "changeAttendedBy";
			const changeAttendedByData = {
				login_employee_id: (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id")),
				login_employee_name: (loginData['employee_name'] != undefined || loginData['employee_name'] != null) ? (loginData['employee_name']) : (localStorage.getItem("employee_name")),
				id: lead_id ? lead_id : searchParams.get("id")
			}
			axios.post(changeAttendedByUrl, changeAttendedByData).then((ChangeAttendedByResponse) => {
				if (ChangeAttendedByResponse.data == true) {
					// console.log("Changed attended by");
				}
				else {
					// console.log("error in changing attended by");
				}
			}).catch(err => { console.log(err); console.log("error in changing attended by"); });
		}
	}

	const openInNewTabCall = (number) => {
		var url = "tel:" + number;
		var click_to_call_a_tag = document.getElementById("click_to_call_a_tag")
		click_to_call_a_tag.setAttribute("href", url);
		click_to_call_a_tag.click()
		// const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		// if (newWindow) newWindow.opener = null
	}

	const blockEmployeeWithLead = async () => {
		const blockEmployeeUrl = global.config.server_url + "blockEmployee";


		var id = props.leadDetails.id;
		var next_reminder = props.leadDetails.next_reminder;
		var login_employee_id = localStorage.getItem("employee_id");

		const blockEmployeeData = {
			id: id,
			login_employee_id: login_employee_id,
		}

		context_data.setProcessing(true);
		await axios.post(blockEmployeeUrl, blockEmployeeData).then((response) => {
			if (response.data == true) {
				context_data.setProcessing(false);
			} else {
				alert("Error in Adding Data");
				context_data.setProcessing(false);
			}
		}).catch(err => { console.log(err); alert("Error in Adding Data"); context_data.setProcessing(false); });
	}

	return (
		<>
			<div className="d-none" style={{ display: "none" }}><a className='d-none' style={{ display: "none" }} href="" id="click_to_call_a_tag"></a></div>
			<input type="button" className="m-2" value="Call" onClick={(e) => { submitCall(e); }} />
		</>
	)
}

export default CreateCall