import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ucwords from 'ucwords';
import CancelReminder from './Components/CancelReminder';
import ChangeLeadStatus from './Components/ChangeLeadStatus';
import ConductedReminder from './Components/ConductedReminder';
import { ContextData } from './Components/ContextData';
import CreateCall from './Components/CreateCall';
import CreateFollowup from './Components/CreateFollowup';
import CreateNotes from './Components/CreateNotes';
import CreateSitevisit from './Components/CreateSitevisit';
import CreateSms from './Components/CreateSms';
import CreateWhatsapp from './Components/CreateWhatsapp';
import { Modal } from "react-bootstrap";
import CloseLead from './Components/CloseLead';
import { Button } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require('moment');

const LeadDetails = () => {
    const context_data = useContext(ContextData)

    var navigate = useNavigate();
    var location = useLocation()

    let [searchParams, setSearchParams] = useSearchParams();
    const [leadId, setLeadId] = useState(false)
    const [leadDetails, setLeadDetails] = useState(false)
    const [loginData, setLoginData] = useState(false);
    const [activityList, setActivityList] = useState(false)
    const [nextReminder, setNextReminder] = useState(null);
    const [closedReason, setClosedReason] = useState(null)

    useEffect(() => {

        console.log(location)
        if (searchParams.get("pending_lead") == "true") {
            console.log(searchParams.get("pending_lead"))
            setUpdateLeadModal(true)
        }
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_LEAD") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            if (isAvailable != true) { navigate("/noAccess") }

            var lead_id = searchParams.get("id");


            setLeadId(lead_id)
            getLeadDetails(lead_id, login_data.employee_type);
            // getActivityList(lead_id);

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    const getLeadDetails = (id = leadId, employee_type = loginData.employee_type) => {
        // console.log("getLeadDetails")
        if (employee_type == "MASTERUSER") {
            context_data.setProcessing(true)
            const getLeadsListUrl = global.config.server_url + "getLeads";
            axios.post(getLeadsListUrl, { id: id, employee_type: employee_type }).then((getLeadsListResponse) => {
                if (getLeadsListResponse.data) {
                    context_data.setProcessing(false)
                    setLeadDetails(getLeadsListResponse.data);
                    getActivityList(getLeadsListResponse.data);
                    changeViewBy(id, getLeadsListResponse.data);
                    setUpdateLeadModal(getLeadsListResponse.data.pending_lead)
                } else {
                    context_data.setProcessing(false)
                    console.log(getLeadsListResponse)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        } else if (employee_type == "EMPLOYEE") {
            context_data.setProcessing(true)
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getLeadsListUrl = global.config.server_url + "getLeads";
            axios.post(getLeadsListUrl, { id: id, employee_type: employee_type, login_employee_id: login_employee_id }).then((getLeadsListResponse) => {
                if (getLeadsListResponse.data) {
                    context_data.setProcessing(false)
                    setLeadDetails(getLeadsListResponse.data);
                    getActivityList(getLeadsListResponse.data);
                    changeViewBy(id, getLeadsListResponse.data);
                    setUpdateLeadModal(getLeadsListResponse.data.pending_lead)
                } else {
                    context_data.setProcessing(false)
                    console.log(getLeadsListResponse)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        }
    }

    const changeViewBy = async (lead_id, lead_details) => {
        if (localStorage.getItem("employee_id").toUpperCase() == lead_details.lead_owner_id.toUpperCase()) {
            const changeViewByUrl = global.config.server_url + "changeViewBy";
            const changeViewByData = {
                login_employee_id: (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id")),
                login_employee_name: (loginData['employee_name'] != undefined || loginData['employee_name'] != null) ? (loginData['employee_name']) : (localStorage.getItem("employee_name")),
                id: lead_id ? lead_id : searchParams.get("id")
            }
            axios.post(changeViewByUrl, changeViewByData).then((ChangeAttendedByResponse) => {
                if (ChangeAttendedByResponse.data == true) {
                    // console.log("Changed attended by");
                }
                else {
                    // console.log("error in changing attended by");
                }
            }).catch(err => { console.log(err); console.log("error in changing attended by"); });
        }
    }

    const getActivityList = async (leadDetails) => {
        getNextReminder(leadDetails);
        if (leadDetails["activity_list"]) {
            var activityList = (JSON.parse(leadDetails["activity_list"]))
            activityList.reverse();
            setActivityList(activityList)
        }
    }

    const getNextReminder = async (leadDetails) => {
        if (leadDetails["next_reminder"]) {
            var next_reminder = JSON.parse(leadDetails["next_reminder"])
            setNextReminder(next_reminder)
            context_data.setReminderType(next_reminder.activity)
        } else {
            setNextReminder(false)
        }
    }

    const openInNewTabLocation = (number) => {
        if (leadDetails.latitude && leadDetails.longitude) {
            var url = "https://www.google.com/maps/place/" + leadDetails.latitude + "+" + leadDetails.longitude;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }


    const swipe = (src) => {
        let url = src;
        window.open(url, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
    }

    function indianNumberFormat(x) {
        if (x) {
            var number = parseFloat(x)
            return number.toLocaleString('en-IN', {
                maximumFractionDigits: 0,
                style: "currency", currency: "INR"
            });
        } else {
            return x
        }
    }

    const datetime_value = (value) => {
        if (moment(value).isValid()) {
            return moment(value).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            return moment(value, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD H:mm:ss", true).isValid()) {
            return moment(value, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            return value
        }
    }

    const maskMobile = (value) => {
        var start = (value.slice(0, 6)); // "01"
        var end = (value.slice(-4)); // "3456"
        let regex = /\d/g;
        let result = end.replace(/./g, "*");
        return start + result
    }

    const [updateLeadModal, setUpdateLeadModal] = useState(false)

    const unblockEmployeeWithLead = async (lead_id) => {
        const unblockEmployeeUrl = global.config.server_url + "unblockEmployee";

        var id = leadDetails.id;
        var login_employee_id = localStorage.getItem("employee_id");

        const unblockEmployeeData = {
            id: id,
            login_employee_id: login_employee_id,
        }

        context_data.setProcessing(true);

        await axios.post(unblockEmployeeUrl, unblockEmployeeData).then((response) => {
            if (response.data == true) {
                context_data.setProcessing(false);
                setUpdateLeadModal(false)
            } else {
                alert("Error in Unblocking Lead");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Unblocking Lead"); context_data.setProcessing(false); });
    }

    useEffect(() => {
        if (leadDetails.pending_lead === 1 || leadDetails.pending_lead === "1") {
            var timer = setTimeout(function () {
                // console.log("called")
                getLeadDetails();
            }, 10000);

            return () => {
                clearTimeout(timer);
            };

        } else {
            setUpdateLeadModal(false)
        }
    }, [leadDetails])


    return (
        <>
            {leadDetails && (
                <>
                    <Modal
                        show={updateLeadModal}
                        onHide={() => setUpdateLeadModal(false)}
                        keyboard={false}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Update Your Activity</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(nextReminder == false || nextReminder == null) ? (
                                <>
                                    <CreateFollowup unblockEmployeeWithLead={unblockEmployeeWithLead} leadDetails={leadDetails} getLeadDetails={getLeadDetails} nextReminder={nextReminder} />
                                    <CreateSitevisit unblockEmployeeWithLead={unblockEmployeeWithLead} leadDetails={leadDetails} getLeadDetails={getLeadDetails} nextReminder={nextReminder} />
                                    <CloseLead unblockEmployeeWithLead={unblockEmployeeWithLead} getLeadDetails={getLeadDetails} nextReminder={nextReminder} leadDetails={leadDetails} setClosedReason={setClosedReason} closedReason={closedReason} />
                                </>
                            ) : (
                                <form className="form-group" >
                                    <div className="row border m-2">
                                        <div className="col-12">
                                            <h5 className=""><u>Next Reminder</u></h5>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="hidden" name="nextReminder_activity_name" id="nextReminder_activity_name" />
                                            <label name="nextReminder_activity" id="nextReminder_activity">Activity: {ucwords(nextReminder.activity.replaceAll("_", " "))} </label>
                                        </div>
                                        <div className="col-md-12">
                                            <label name="nextReminder_notes" id="nextReminder_notes">Notes: {(nextReminder.notes_data)}</label>
                                        </div>
                                        <div className="col-md-12">
                                            <label name="nextReminder_created_at" id="nextReminder_created_at">Created at: {(nextReminder.created_at)}</label>
                                        </div>
                                        <div className="col-md-12">
                                            <label name="nextReminder_created_by" id="nextReminder_created_by">Created By: {(nextReminder.created_by_id) + " : " + (nextReminder.created_by_name)}</label>
                                        </div>
                                        <div className="col-md-12">
                                            <label name="nextReminder_reminder" id="nextReminder_reminder">Reminder: {(nextReminder.reminder)}</label>
                                        </div>
                                        <div className="col-md-12">
                                            {(context_data.reminderType &&
                                                (context_data.reminderType.toLowerCase() == "followup" || context_data.reminderType.toLowerCase() == "sitevisit") ?
                                                (<>
                                                    <ConductedReminder unblockEmployeeWithLead={unblockEmployeeWithLead} getLeadDetails={getLeadDetails} nextReminder={nextReminder} closedReason={closedReason} setClosedReason={setClosedReason} leadDetails={leadDetails} />
                                                    <CancelReminder unblockEmployeeWithLead={unblockEmployeeWithLead} getLeadDetails={getLeadDetails} nextReminder={nextReminder} closedReason={closedReason} setClosedReason={setClosedReason} />
                                                </>) : "")}
                                        </div>

                                    </div>
                                </form>
                            )}

                        </Modal.Body>
                        <Modal.Footer className="updateLeadModalFooter">
                            <Button variant="primary" onClick={() => getLeadDetails()}>Refresh</Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                                    {(context_data.prevPage == "/allLeads" ? <Breadcrumb.Item onClick={() => navigate("/allLeads")}>Leads</Breadcrumb.Item> : "")}
                                    {(context_data.prevPage == "/incomingLeads" ? <Breadcrumb.Item onClick={() => navigate("/incomingLeads")}>Incoming Leads</Breadcrumb.Item> : "")}
                                    {(context_data.prevPage == "/noActivityList" ? <Breadcrumb.Item onClick={() => navigate("/noActivityList")}>No Activity Leads</Breadcrumb.Item> : "")}
                                    {(context_data.prevPage == "/totalPendingActivityList" ? <Breadcrumb.Item onClick={() => navigate("/totalPendingActivityList")}>Total Pending Activity Leads</Breadcrumb.Item> : "")}
                                    {(context_data.prevPage == "/tillDatePendingActivityList" ? <Breadcrumb.Item onClick={() => navigate("/tillDatePendingActivityList")}>Till Date Pending Activity Leads</Breadcrumb.Item> : "")}
                                    <Breadcrumb.Item active>Lead Details</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <>
                                        <div className="col-md-6">
                                            <table className='w-100'>
                                                <tr><td className='lead_details_td'>Lead ID:</td><td>{leadDetails.id}</td></tr>
                                                <tr><td className='lead_details_td'>Name:</td><td>{leadDetails.salutation ? ucwords((leadDetails.salutation).toLowerCase()) : ""} {leadDetails.name ? ucwords((leadDetails.name).toLowerCase()) : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Mobile:</td><td>{leadDetails.mobile ? (loginData.employee_type == "MASTERUSER" ? leadDetails.mobile : maskMobile(leadDetails.mobile)) : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Secondary Mobile:</td><td>{leadDetails.secondary_mobile ? leadDetails.secondary_mobile : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Whatsapp:</td><td>{leadDetails.whatsapp ? leadDetails.whatsapp : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Email:</td><td>{leadDetails.email ? (leadDetails.email).toLowerCase() : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Secondary Email:</td><td>{leadDetails.secondary_email ? (leadDetails.secondary_email).toLowerCase() : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Builder:</td><td>{leadDetails.builder_name ? ucwords((leadDetails.builder_name).toLowerCase()) : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Project:</td><td>{leadDetails.project_name ? ucwords((leadDetails.project_name).toLowerCase()) : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Lead Owner ID:</td><td>{leadDetails.lead_owner_id ? leadDetails.lead_owner_id.toUpperCase() : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Lead Owner Name:</td><td>{leadDetails.lead_owner_name ? ucwords((leadDetails.lead_owner_name).toLowerCase()) : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Lead Added on:</td><td>{leadDetails.create_datetime ? datetime_value(leadDetails.create_datetime) : "-"}</td></tr>
                                                {(loginData.employee_type == "MASTERUSER") ? <tr><td className='lead_details_td'>Lead Source:</td><td>{leadDetails.first_source_of_enquiry ? (leadDetails.first_source_of_enquiry.toUpperCase()) : "-"}</td></tr> : ""}
                                                {(leadDetails.latitude && leadDetails.longitude) && (<tr><td className='lead_details_td'>Location:</td><td><a onClick={openInNewTabLocation} className='a_tag_location'>{"Click to open"}</a>{ }</td></tr>)}
                                            </table>
                                        </div>
                                        <div className="col-md-6">
                                            <table className='w-100'>
                                                <tr><td className='lead_details_td'>Lead Status: </td><td><ChangeLeadStatus unblockEmployeeWithLead={unblockEmployeeWithLead} getLeadDetails={getLeadDetails} nextReminder={nextReminder} leadDetails={leadDetails} setClosedReason={setClosedReason} closedReason={closedReason} /></td></tr>
                                                <tr><td className='lead_details_td'>Lead Clicked Count:</td><td>{leadDetails.lead_clicked_count}</td></tr>
                                                <tr><td className='lead_details_td'>DOB:</td><td>{leadDetails.date_of_birth ? (leadDetails.date_of_birth) : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Occupation:</td><td>{leadDetails.occupation ? ucwords((leadDetails.occupation).toLowerCase()) : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Company:</td><td>{leadDetails.company ? ucwords((leadDetails.company).toLowerCase()) : "-"}</td></tr>
                                                {leadDetails.occupation && leadDetails.occupation.toLowerCase() == "business" ? (<tr><td className='lead_details_td'>Industry:</td><td>{leadDetails.industry ? ucwords((leadDetails.industry).toLowerCase()) : "-"}</td></tr>) : ""}
                                                {leadDetails.occupation && leadDetails.occupation.toLowerCase() == "salaried" ? (<tr><td className='lead_details_td'>Designation:</td><td>{leadDetails.designation ? ucwords((leadDetails.designation).toLowerCase()) : "-"}</td></tr>) : ""}
                                                <tr><td className='lead_details_td'>Income:</td><td>{leadDetails.income ? leadDetails.income : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Min Budget:</td><td>{leadDetails.min_budget ? indianNumberFormat(leadDetails.min_budget) : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Max Budget:</td><td>{leadDetails.max_budget ? indianNumberFormat(leadDetails.max_budget) : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Location:</td><td>{leadDetails.location ? leadDetails.location : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Possession Duration:</td><td>{leadDetails.possession_duration ? leadDetails.possession_duration : "-"}</td></tr>
                                                <tr><td className='lead_details_td'>Property Type:</td><td>{leadDetails.property_type ? ucwords(leadDetails.property_type.toLowerCase()) : "-"}</td></tr>

                                                {leadDetails.property_type && <>
                                                    {(leadDetails.property_type.toLowerCase() == "gated community plots" || leadDetails.property_type.toLowerCase() == "individual plots") ? (<>
                                                        <tr><td className='lead_details_td'>Plot Size:</td><td>{leadDetails.plot_size ? leadDetails.property_type : "-"}</td></tr>
                                                    </>) : ""}

                                                    {(leadDetails.property_type.toLowerCase() == "apartment" || leadDetails.property_type.toLowerCase() == "villa" || leadDetails.property_type.toLowerCase() == "independent house" || leadDetails.property_type.toLowerCase() == "row houses") ? (<>
                                                        <tr><td className='lead_details_td'>Bedroom Preference:</td><td>{leadDetails.bedroom_preference ? leadDetails.bedroom_preference : "-"}</td></tr>
                                                    </>) : ""}

                                                    {(leadDetails.property_type.toLowerCase() == "apartment") ? (<>
                                                        <tr><td className='lead_details_td'>Min Carpet Area:</td><td>{leadDetails.min_carpet_area ? leadDetails.min_carpet_area : "-"}</td></tr>
                                                        <tr><td className='lead_details_td'>Max Carpet Area:</td><td>{leadDetails.max_carpet_area ? leadDetails.max_carpet_area : "-"}</td></tr>
                                                    </>) : ""}
                                                </>}
                                                <tr><td className='lead_details_td'>Purpose:</td><td>{leadDetails.purpose ? leadDetails.purpose : "-"}</td></tr>
                                            </table>
                                        </div>

                                        <div className="col-md-6"><input type="button" value="Edit" className="btn btn-primary" onClick={() => navigate({ pathname: "/editLead/", search: "id=" + leadDetails.id })} /></div>
                                    </>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row border text-center">
                                    <div className="col-12">
                                        <CreateNotes getLeadDetails={getLeadDetails} />
                                        <CreateFollowup unblockEmployeeWithLead={unblockEmployeeWithLead} leadDetails={leadDetails} getLeadDetails={getLeadDetails} nextReminder={nextReminder} />
                                        <CreateSitevisit unblockEmployeeWithLead={unblockEmployeeWithLead} leadDetails={leadDetails} getLeadDetails={getLeadDetails} nextReminder={nextReminder} />
                                        <CreateCall leadDetails={leadDetails} getLeadDetails={getLeadDetails} />
                                        <CreateWhatsapp leadDetails={leadDetails} getLeadDetails={getLeadDetails} />
                                        <CreateSms leadDetails={leadDetails} getLeadDetails={getLeadDetails} />
                                    </div>

                                </div>
                                <div className="row border">
                                    <div className="col-12 p-0">

                                        <div className="">

                                            {(nextReminder == false || nextReminder == null) ? (<div><h3 className="text-center m-5">No Reminder</h3></div>) : (
                                                <form className="form-group" >
                                                    <div className="row border m-2">
                                                        <div className="col-12">
                                                            <h5 className=""><u>Next Reminder</u></h5>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input type="hidden" name="nextReminder_activity_name" id="nextReminder_activity_name" />
                                                            <label name="nextReminder_activity" id="nextReminder_activity">Activity: {ucwords(nextReminder.activity.replaceAll("_", " "))} </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label name="nextReminder_notes" id="nextReminder_notes">Notes: {(nextReminder.notes_data)}</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label name="nextReminder_created_at" id="nextReminder_created_at">Created at: {(nextReminder.created_at)}</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label name="nextReminder_created_by" id="nextReminder_created_by">Created By: {(nextReminder.created_by_id) + " : " + (nextReminder.created_by_name)}</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label name="nextReminder_reminder" id="nextReminder_reminder">Reminder: {(nextReminder.reminder)}</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {(context_data.reminderType &&
                                                                (context_data.reminderType.toLowerCase() == "followup" || context_data.reminderType.toLowerCase() == "sitevisit") ?
                                                                (<>
                                                                    <ConductedReminder unblockEmployeeWithLead={unblockEmployeeWithLead} getLeadDetails={getLeadDetails} nextReminder={nextReminder} closedReason={closedReason} setClosedReason={setClosedReason} leadDetails={leadDetails} />
                                                                    <CancelReminder unblockEmployeeWithLead={unblockEmployeeWithLead} getLeadDetails={getLeadDetails} nextReminder={nextReminder} closedReason={closedReason} setClosedReason={setClosedReason} />
                                                                </>) : "")}
                                                        </div>

                                                    </div>
                                                </form>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {(activityList == false || activityList == null) ? (<div><h3 className="text-center m-5">No Acitivities</h3></div>) :
                                (
                                    <div className="col-12">
                                        <div className="table-responsive div_activity_list">
                                            <table id="activity_list" className="table table-striped table-bordered" >
                                                <thead className="bg-dark text-light">
                                                    <th className="activity_list_td">Sl</th>
                                                    <th className="activity_list_td">Activity</th>
                                                    <th className="activity_list_td">Notes</th>
                                                    <th className="activity_list_td">File</th>
                                                    <th className="activity_list_td">Reminder</th>
                                                    <th className="activity_list_td">Created at</th>
                                                    <th className="activity_list_td">Created by ID</th>
                                                    <th className="activity_list_td">Created by Name</th>
                                                </thead>
                                                <tbody>
                                                    {activityList.map((activity, index) =>
                                                        <tr>
                                                            <td className="text-center activity_list_td">{index + 1}</td>
                                                            <td className="activity_list_td">{ucwords(activity.activity)}</td>
                                                            <td className="activity_list_td">{activity.notes_data}</td>
                                                            <td className="activity_list_td">
                                                                {(activity.images) ?
                                                                    (((activity.images).trim().split(",")).map((img) => <img className='img_table_activity_list' src={'/uploads/' + img} onClick={(e) => swipe(e.target.src)} />))
                                                                    : (activity.ivr_recording) ?
                                                                        (<audio controls>
                                                                            <source src={"ivr_recordings/" + activity.ivr_recording} type="audio/mpeg" />
                                                                            Your browser does not support the audio element.
                                                                        </audio>)
                                                                        : ""}
                                                            </td>
                                                            <td className="activity_list_td">{(activity.reminder) ? (activity.reminder) : ("-")}</td>
                                                            <td className="activity_list_td">{activity.created_at}</td>
                                                            <td className="activity_list_td">{activity.created_by_id}</td>
                                                            <td className="activity_list_td">{activity.created_by_name}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            }

                        </div>


                    </div>
                </>
            )}
        </>
    )
}

export default LeadDetails