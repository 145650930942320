import React, { useContext } from 'react'
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import CachedIcon from '@mui/icons-material/Cached';
import { ContextData } from './Components/ContextData';
import { Box, LinearProgress } from '@mui/material';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require('moment')

const LeadsList = () => {
    const context_data = useContext(ContextData)

    var navigate = useNavigate();
    var location = useLocation();

    const [leadsData, setLeadsData] = useState([]);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    var gridRef = useRef();

    const [progressBar, setProgressBar] = useState(0)

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_LEAD") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getLeadsCount(login_data.employee_type)

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    async function getLeadsCount(employee_type) {
        context_data.setProcessing(true)
        if (employee_type == "MASTERUSER") {
            const getLeadsCountUrl = global.config.server_url + "getLeadsCount";
            await axios.post(getLeadsCountUrl, { employee_type: employee_type, all_data: true }).then((getLeadsCountResponse) => {
                if (getLeadsCountResponse.data) {
                    context_data.setProcessing(false)
                    var count = getLeadsCountResponse.data.count
                    count = (count / 100);
                    var loop_value = Math.ceil(count)
                    getLeads(loop_value, employee_type)
                } else {
                    console.log("error in getting leads count")
                    getAllLeads()
                    context_data.setProcessing(false)
                }
            }).catch(err => { console.log(err); getAllLeads(); context_data.setProcessing(false) });
        } else if (employee_type == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getLeadsCountUrl = global.config.server_url + "getLeadsCount";
            await axios.post(getLeadsCountUrl, { employee_type: employee_type, all_data: true, login_employee_id: login_employee_id }).then((getLeadsCountResponse) => {
                if (getLeadsCountResponse.data) {
                    context_data.setProcessing(false)
                    var count = getLeadsCountResponse.data.count
                    count = (count / 100);
                    var loop_value = Math.ceil(count)
                    getLeads(loop_value, employee_type)
                } else {
                    console.log("error in getting leads count")
                    getAllLeads()
                    context_data.setProcessing(false)
                }
            }).catch(err => { console.log(err); getAllLeads(); context_data.setProcessing(false) });
        }
    }

    async function getLeads(loop_value, employee_type) {
        var progress_add_value = 100 / loop_value;

        for (let i = 0; i < loop_value; i++) {
            const offset_value = i * 100;
            if (employee_type == "MASTERUSER") {
                const getLeadsUrl = global.config.server_url + "getLeadsUsingOffset";
                await axios.post(getLeadsUrl, { employee_type: employee_type, offset_value: offset_value }).then((getLeadsResponse) => {
                    if (getLeadsResponse.data) {
                        var leads = getLeadsResponse.data
                        setLeadsData(old => [...old, ...leads]);
                        context_data.setProcessing(false)
                    } else {
                        console.log("error in getting data")
                        context_data.setProcessing(false)
                    }
                }).catch(err => { console.log(err); context_data.setProcessing(false) });
            } else if (employee_type == "EMPLOYEE") {
                var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
                const getLeadsUrl = global.config.server_url + "getLeadsUsingOffset";
                await axios.post(getLeadsUrl, { employee_type: employee_type, offset_value: offset_value, login_employee_id: login_employee_id }).then((getLeadsResponse) => {
                    if (getLeadsResponse.data) {
                        var leads = getLeadsResponse.data
                        setLeadsData(old => [...old, ...leads]);
                        context_data.setProcessing(false)
                    } else {
                        alert("error in getting data")
                        context_data.setProcessing(false)
                    }
                }).catch(err => { console.log(err); context_data.setProcessing(false) });
            }
            setProgressBar(i * progress_add_value)
        }
        setProgressBar(100)
    }

    // async function getLeads(employee_type) {
    //     return
    //     context_data.setProcessing(true)
    //     if (employee_type == "MASTERUSER") {
    //         const getLeadsUrl = global.config.server_url + "getLeadsInitial";
    //         await axios.post(getLeadsUrl, { employee_type: employee_type, all_data: true }).then((getLeadsResponse) => {
    //             if (getLeadsResponse.data) {
    //                 setLeadsData(getLeadsResponse.data);
    //                 context_data.setProcessing(false)
    //                 getAllLeads(employee_type)
    //             } else {
    //                 alert("error in getting data")
    //                 context_data.setProcessing(false)
    //                 getAllLeads(employee_type)
    //             }
    //         }).catch(err => { console.log(err); context_data.setProcessing(false) });
    //     } else if (employee_type == "EMPLOYEE") {
    //         var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
    //         const getLeadsUrl = global.config.server_url + "getLeadsInitial";
    //         await axios.post(getLeadsUrl, { employee_type: employee_type, all_data: true, login_employee_id: login_employee_id }).then((getLeadsResponse) => {
    //             if (getLeadsResponse.data) {
    //                 setLeadsData(getLeadsResponse.data);
    //                 context_data.setProcessing(false)
    //                 getAllLeads(employee_type)
    //             } else {
    //                 alert("error in getting data")
    //                 context_data.setProcessing(false)
    //                 getAllLeads(employee_type)
    //             }
    //         }).catch(err => { console.log(err); context_data.setProcessing(false) });
    //     }
    // }

    async function getAllLeads(employee_type) {
        var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
        const getLeadsUrl = global.config.server_url + "getLeads";
        await axios.post(getLeadsUrl, { employee_type: employee_type, all_data: true, login_employee_id: login_employee_id }).then((getLeadsResponse) => {
            if (getLeadsResponse.data) {
                setLeadsData(getLeadsResponse.data);
            } else {
                console.log(getLeadsResponse)
            }
        }).catch(err => console.log(err));
    }

    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            sortable: true,
            autoHeight: true,
        }
    )

    const ucwords_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = params.data[column_name] ? ucwords(params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
        return newValue
    }

    const lowercase_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = params.data[column_name] ? (params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
        return newValue
    }

    const datetime_value = (params) => {
        var column_name = (params.column.colId)
        if (moment(params.data[column_name]).isValid()) {
            return moment(params.data[column_name]).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(params.data[column_name], "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            return moment(params.data[column_name], "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(params.data[column_name], "YYYY-MM-DD H:mm:ss", true).isValid()) {
            return moment(params.data[column_name], "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            return params.data[column_name]
        }
    }

    const btnRenderer = (params) => {
        if (loginData.employee_type == "MASTERUSER") {
            let btn = document.createElement("button");
            let url = "/leadDetails/?id=" + params.data.id;
            btn.innerHTML = "Details";
            btn.classList = "table_list_btn btn btn-info"
            btn.onclick = function () {
                context_data.setPrevPage(location.pathname)
                const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                if (newWindow) newWindow.opener = null
            };
            return btn;
        } else {
            let btn = document.createElement("button");
            btn.innerHTML = "Details";
            btn.classList = "table_list_btn btn btn-info"
            btn.onclick = function () {
                context_data.setPrevPage(location.pathname)
                navigate({ pathname: "/leadDetails/", search: "id=" + params.data.id, state: { id: params.data.id } })
            };
            return btn;
        }
    }

    const openLead = (params) => {
        context_data.setPrevPage(location.pathname)
        if (loginData.employee_type == "MASTERUSER") {
            let url = "/leadDetails/?id=" + params.data.id;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        } else {
            navigate({ pathname: "/leadDetails/", search: "id=" + params.data.id, state: { id: params.data.id } })
        }
    }

    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    const next_reminder_type_value = (params) => {
        var newValue = params.data.next_reminder
        if (newValue) {
            var newValue_json = JSON.parse(newValue)
            var data = ucwords(newValue_json.activity)
            return data
        } else {
            return "-"
        }
    }

    const attended_value = (params) => {
        if (params.data.attended_name) { return "Yes" } else { return "No" }
    }

    const getRowStyle = params => {
        var newValue = params.data.next_reminder

        if (newValue) {
            return
        }
        if (params.data.lead_status && (params.data.lead_status.toLowerCase() == "incoming")) {
            return { color: "#842029", backgroundColor: "#f8d7da", borderColor: "#f5c2c7" };
        }
    }

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    const [refreshTime, setRefreshTime] = useState(moment(new Date()).format("DD-MMM-YYYY h:mm:ss A"));

    const refreshPage = () => {
        let current_datetime = moment(new Date()).format("DD-MMM-YYYY h:mm:ss A");
        setRefreshTime(current_datetime);
        setLeadsData([])
        getLeadsCount(loginData.employee_type);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Leads</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12" style={{ marginBottom: "15px" }}>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" color={progressBar == 100 ? "success" : "secondary"} value={progressBar} />
                        </Box>
                    </div>
                    <div className="col-md-4 d-flex mb-2">
                        <div onClick={() => { document.getElementById("span_refresh").value = "Refreshing..."; refreshPage(); }} style={{ cursor: "pointer" }}>
                            <span id="span_refresh">Last refreshed at: {refreshTime} </span>
                            <CachedIcon className="refreshIcon" onClick={() => { document.getElementById("span_refresh").value = "Refreshing..."; refreshPage(); }} />
                        </div>
                        <input className='btn btn-primary mx-auto' type="button" value="Add New Lead" onClick={() => navigate("/addLead")} />
                    </div>
                    <div className="col-md-4">
                        <h3 className='text-center'>All Leads</h3>
                    </div>
                    <div className="col-md-4">
                        <input className='form-control' type="text" id="filter-text-box" placeholder="Search..." onInput={onFilterTextBoxChanged} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {(leadsData) ? (
                            <>
                                <div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
                                    <AgGridReact
                                        defaultColDef={defaultColDef}
                                        rowData={leadsData}
                                        pagination={true}
                                        paginationAutoPageSize={true}
                                        animateRows={true}
                                        ref={gridRef}
                                        onGridReady={() => autoSizeAll(false)}
                                        onFirstDataRendered={() => autoSizeAll(false)}
                                        getRowStyle={getRowStyle}
                                    >
                                        <AgGridColumn width="100" headerName="Sl No" valueGetter={"node.rowIndex+1"} ></AgGridColumn>
                                        <AgGridColumn onCellClicked={openLead} cellClass={"column_lead_id"} filter='agTextColumnFilter' headerName="Lead ID" field="id" ></AgGridColumn>
                                        <AgGridColumn filter='agDateColumnFilter' headerName="Lead Added on" field="create_datetime" valueGetter={datetime_value}></AgGridColumn>
                                        {loginData.employee_type == "MASTERUSER" ? (<AgGridColumn filter='agTextColumnFilter' headerName="Lead Owner" field="lead_owner_name" valueGetter={ucwords_value}></AgGridColumn>) : ""}
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Name" field="name" valueGetter={ucwords_value} ></AgGridColumn>
                                        {loginData.employee_type == "MASTERUSER" ? (<AgGridColumn filter='agTextColumnFilter' headerName="Mobile" field="mobile" ></AgGridColumn>) : ""}
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Project" field="project_name" valueGetter={ucwords_value}></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Status" field="lead_status" valueGetter={ucwords_value} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Next Activity" field="next_reminder" valueGetter={next_reminder_type_value}></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Attended" field="attended_name" valueGetter={attended_value}></AgGridColumn>
                                        <AgGridColumn headerName="Actions" cellRenderer={btnRenderer} ></AgGridColumn>
                                    </AgGridReact>
                                </div>
                                {/* <div className="">
                                    <table id="" className="display table table-striped table-bordered compact" width="100%" >
                                        <thead className="bg-dark text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>id</th>
                                                <th>name</th>
                                                <th>mobile</th>
                                                <th>lead_owner_name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {leadsData.map((lead, index) =>
                                                <tr key={lead.id} >
                                                    <td className="">{index + 1}</td>
                                                    <td className="">{(lead.id) ? lead.id : "-"}</td>
                                                    <td className="">{(lead.name) ? lead.name : "-"}</td>
                                                    <td className="">{(lead.mobile) ? lead.mobile : "-"}</td>
                                                    <td className="">{(lead.lead_owner_name) ? lead.lead_owner_name : "-"}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div> */}

                            </>
                        ) : (<div><h3 className="text-center m-5">No Data Available</h3></div>)}
                    </div>
                </div>
            </div>

        </>
    )
}

export default LeadsList