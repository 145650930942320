import React, { useContext, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { Autocomplete, Button, Stack } from '@mui/material'
import ucwords from 'ucwords'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

const EmployeeAdd = (props) => {

    const context_data = useContext(ContextData)

    var navigate = useNavigate();

    var employeeRightsList = global.config.employee_rights_list

    const [employeesData, setEmployeesData] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    const [employeeRights, setEmployeeRights] = useState([])
    const [empJoinDate, setEmpJoinDate] = useState(new Date())
    const [employeeType, setEmployeeType] = useState("Employee");


    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_EMPLOYEE") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getEmployees();
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }

    }, [])

    function getEmployees() {
        context_data.setProcessing(true)
        const getEmployeesUrl = global.config.server_url + "getEmployees";
        axios.post(getEmployeesUrl, { all_data_list: true }).then((getEmployeesResponse) => {
            if (getEmployeesResponse.data) {
                setEmployeesData(getEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const [errorEmployeeId, setErrorEmployeeId] = useState(false);
    const [errorEmployeeMobile, setErrorEmployeeMobile] = useState(false);
    const [errorEmployeeEmail, setErrorEmployeeEmail] = useState(false);

    const checkEmployeeId = (e) => {
        var employee_id = e.target.value.toUpperCase()
        for (var i = 0; i < employeesData.length; i++) {
            if (employeesData[i].employee_id == employee_id) {
                setErrorEmployeeId(true)
                break;
            } else {
                setErrorEmployeeId(false)
            }
        }
    }

    const checkEmployeeMobile = (e) => {
        var employee_mobile = e.target.value.toUpperCase()
        for (var i = 0; i < employeesData.length; i++) {
            if (employeesData[i].employee_mobile == employee_mobile && employeesData[i].employee_status == "ACTIVE") {
                setErrorEmployeeMobile(true)
                break;
            } else {
                setErrorEmployeeMobile(false)
            }
        }
    }

    const checkEmployeeEmail = (e) => {
        var employee_email = e.target.value.toUpperCase()
        for (var i = 0; i < employeesData.length; i++) {
            if (employeesData[i].employee_email) {
                if ((employeesData[i].employee_email.toUpperCase()) == employee_email && employeesData[i].employee_status == "ACTIVE") {
                    setErrorEmployeeEmail(true)
                    break;
                } else {
                    setErrorEmployeeEmail(false)
                }
            } else {
                setErrorEmployeeEmail(false)
            }
        }
    }

    const submitAddEmployee = (e) => {
        e.preventDefault();

        if (errorEmployeeId || errorEmployeeMobile || errorEmployeeEmail) { alert("Clear the errors"); return; }
        else {
            context_data.setProcessing(true);
            var employee_rights_arr = [];
            for (let i = 0; i < employeeRights.length; i++) {
                const element = employeeRights[i];
                employee_rights_arr.push(element.empRight)
            }

            var employee_rights = employee_rights_arr.toString();

            var employee_id = (e.target.employee_id.value).toUpperCase();
            var employee_name = (e.target.employee_name.value).toUpperCase();
            var employee_mobile = (e.target.employee_mobile.value).toUpperCase();
            var employee_email = (e.target.employee_email.value).toUpperCase();
            var employee_gender = (e.target.employee_gender.value).toUpperCase();
            var employee_joindate = (e.target.employee_joindate.value).toUpperCase();
            var employee_type = (e.target.employee_type.value).toUpperCase();
            var employee_designation = e.target.employee_designation ? (e.target.employee_designation.value).toUpperCase() : "";
            var employee_status = (e.target.employee_status.value).toUpperCase();

            var submitAddEmployeeData = {
                employee_rights: employee_rights,
                employee_id: employee_id,
                employee_name: employee_name,
                employee_mobile: employee_mobile,
                employee_email: employee_email,
                employee_gender: employee_gender,
                employee_joindate: employee_joindate,
                employee_type: employee_type,
                employee_designation: employee_designation,
                employee_status: employee_status,

                login_employee_id: (localStorage.getItem("employee_id")),
                login_employee_name: (localStorage.getItem("employee_name")),

            }

            var submitAddEmployeeUrl = global.config.server_url + "addEmployee";
            axios.post(submitAddEmployeeUrl, submitAddEmployeeData).then((response) => {
                if (response.data === true) {
                    alert("Employee Added");
                    context_data.setProcessing(false);
                    navigate({ pathname: "/allEmployees", state: { login_data: loginData } });
                } else {
                    alert("Error in Adding Employee");
                    context_data.setProcessing(false);
                }
            }).catch(err => { console.log(err); alert("Error in Adding Employee"); context_data.setProcessing(false); });
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/allEmployees")}>Employees</Breadcrumb.Item>
                            <Breadcrumb.Item active>Add Employee</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h3 className='text-center'>Add Employee</h3>
                    </div>
                </div>
                <form onSubmit={submitAddEmployee}>
                    <div className="row ">
                        <div className="col-md-12">
                            <fieldset className='fieldset_info'>
                                <legend className='fieldset_info_legend'>Employee Information</legend>
                                <div className="row">

                                    <div className="col-md-4 my-2">
                                        <TextField fullWidth label="Emp ID" name="employee_id" required onChange={checkEmployeeId} error={errorEmployeeId} />
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <TextField fullWidth label="Name" name="employee_name" required />
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="Mobile" name="employee_mobile" required onChange={checkEmployeeMobile} error={errorEmployeeMobile} />
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <TextField fullWidth label="Email" name="employee_email" required onChange={checkEmployeeEmail} error={errorEmployeeEmail} />
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={["Male", "Female", "Other"]}
                                            renderInput={(params) => <TextField {...params} label="Gender" name="employee_gender" required />}
                                        />
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack>
                                                <MobileDatePicker
                                                    label="Join Date"
                                                    inputFormat="dd-MMM-yyyy"
                                                    value={empJoinDate}
                                                    onChange={(e) => setEmpJoinDate(e)}
                                                    renderInput={(params) => <TextField {...params} name="employee_joindate" />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={["Employee", "Masteruser"]}
                                            defaultValue={"Employee"}
                                            onChange={(e, newValue) => setEmployeeType(newValue)}
                                            renderInput={(params) => <TextField {...params} label="Type" name="employee_type" required />}
                                        />
                                    </div>
                                    {(employeeType == "Employee" &&
                                        <div className="col-md-4 my-2">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={["Sales Manager", "Team Leader", "Sales Executive", "Pre Sales Executive"]}
                                                renderInput={(params) => <TextField {...params} label="Designation" name="employee_designation" required />}
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-4 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={["Active", "Inactive"]}
                                            defaultValue={"Active"}
                                            renderInput={(params) => <TextField {...params} label="Status" name="employee_status" required />}
                                        />
                                    </div>
                                    <div className="col-md-8 my-2">
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={employeeRightsList}
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                setEmployeeRights(newValue);
                                            }}
                                            value={employeeRights}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Emp Rights"
                                                    placeholder="Emp Rights"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <Button type="submit" variant="contained" color="success">Submit</Button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default EmployeeAdd