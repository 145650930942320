import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { ContextData } from './ContextData';
import ReminderDetails from './ReminderDetails';

var moment = require("moment");

const CreateFollowup = (props) => {

    const context_data = useContext(ContextData)

    let [searchParams, setSearchParams] = useSearchParams();
    const [loginData, setLoginData] = useState(false);

    useEffect(() => {
        setLoginData(localStorage.getItem("login_data"))
        var login_data = JSON.parse(localStorage.getItem("login_data"))
        setLoginData(login_data)

        var lead_id = searchParams.get("id");
        setLeadId(lead_id)
    }, [])

    const [leadId, setLeadId] = useState(false)

    const [addFollowUpShow, setAddFollowUpShow] = useState(false);

    const handleAddFollowUpShow = () => {
        context_data.setReminderType("followup");
        if (props.nextReminder) {
            alert("Please Complete Pending Action");
        } else {
            context_data.setPickerDatetime(moment(new Date()).add((5 - ((moment(new Date())).minute() % 5)), 'm').toDate());
            setAddFollowUpShow(true);
        }
    }
    const handleAddFollowUpClose = () => { setAddFollowUpShow(false); }

    async function submitAddReminder(e) {
        e.preventDefault()
        changeAttendedBy()
        props.unblockEmployeeWithLead()

        context_data.setProcessing(true)
        var notes = e.target.notes.value;
        notes = notes.replace(/["']/g, "");
        notes = notes.replace(/\s+/g, ' ').trim()

        var reminder_type = e.target.type.value;
        var reminder_datetime = context_data.pickerDatetime;
        reminder_datetime.setSeconds(0);
        var id = (leadId != undefined || leadId != null) ? leadId : searchParams.get("id");

        const addReminderData = {
            notes: notes,
            reminder_datetime: reminder_datetime,
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
            id: id
        };

        var addReminderUrl = global.config.server_url + "addFollowUp";

        await axios.post(addReminderUrl, addReminderData).then((response) => {
            if (response.data == true) {
                context_data.setProcessing(false);
                handleAddFollowUpClose();
                props.getLeadDetails();
            } else {
                alert("Error in Creating Reminder");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Reminder"); context_data.setProcessing(false); });

    }

    const changeAttendedBy = async (lead_id = props.leadDetails.id, lead_details = props.leadDetails) => {

        if (localStorage.getItem("employee_id").toUpperCase() == lead_details.lead_owner_id.toUpperCase()) {
            const changeAttendedByUrl = global.config.server_url + "changeAttendedBy";
            const changeAttendedByData = {
                login_employee_id: (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id")),
                login_employee_name: (loginData['employee_name'] != undefined || loginData['employee_name'] != null) ? (loginData['employee_name']) : (localStorage.getItem("employee_name")),
                id: lead_id ? lead_id : searchParams.get("id")
            }
            axios.post(changeAttendedByUrl, changeAttendedByData).then((ChangeAttendedByResponse) => {
                if (ChangeAttendedByResponse.data == true) {
                    // console.log("Changed attended by");
                }
                else {
                    // console.log("error in changing attended by");
                }
            }).catch(err => { console.log(err); console.log("error in changing attended by"); });
        }
    }

    return (
        <>
            {/* Modal for Follow Up */}
            <Modal
                show={addFollowUpShow}
                onHide={() => handleAddFollowUpShow()}
                keyboard={false}
                centered
            >
                <Modal.Header >
                    <Modal.Title>Add Follow Up</Modal.Title>
                </Modal.Header>
                <form className="form-group" onSubmit={(e) => submitAddReminder(e)}>
                    <input type="hidden" name="type" value="followup" />
                    <Modal.Body>
                        <div>
                            <ReminderDetails />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <input type="button" className="btn btn-primary" id="" onClick={() => addFollowUp()} value="Add" /> */}
                        <input type="submit" className="btn btn-primary" id="" value="Add" />
                        <input type="button" className="btn btn-danger" id="" onClick={() => handleAddFollowUpClose()} value="Cancel" />
                    </Modal.Footer>
                </form>
            </Modal>

            <input type="button" disabled={(props.leadDetails.lead_status.toLowerCase() == "closed" || props.leadDetails.lead_status.toLowerCase() == "booking confirmed") ? "disabled" : ""} className="m-2" value="Follow Up" onClick={() => handleAddFollowUpShow()} />
        </>
    )
}

export default CreateFollowup