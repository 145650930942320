import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ContextData } from '../Components/ContextData';
import ucwords from 'ucwords';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import { FloatingLabel, Form, Table } from 'react-bootstrap'
import html2canvas from "html2canvas";

var moment = require('moment')

const LeadsSummary = () => {
    const exportRef = useRef();
    var navigate = useNavigate();
    var gridRef = useRef();

    const context_data = useContext(ContextData)

    const [leadsSummaryData, setLeadsSummaryData] = useState(false);
    const [sumLeadsSummaryData, setSumLeadsSummaryData] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
                getEmployees(login_data.employee_type);
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_LEAD") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            handleSelectChange()

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])


    const [reportRequestDate, setReportRequestDate] = useState("All Data")

    const [employeesData, setEmployeesData] = useState(false);

    async function getEmployees(employee_type) {
        context_data.setProcessing(true)
        const getEmployeesUrl = global.config.server_url + "getEmployees";
        await axios.post(getEmployeesUrl, { all_data_active: true }).then((getEmployeesResponse) => {
            if (getEmployeesResponse.data) {
                setEmployeesData(getEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const [selectedEmployeeId, setSelectedEmployeeId] = useState("")

    const getLeadsSummary = async (e) => {

        e.preventDefault();
        var type = e.target.view_type ? e.target.view_type.value : "all_data";
        var employee_id = e.target.employee ? e.target.employee.value : "";
        setSelectedEmployeeId(employee_id)

        var getLeadsSummaryData = "";

        switch (type) {
            case "all_data":
                getLeadsSummaryData = { employee_id: employee_id, all_data: true }
                setReportRequestDate("All Data")
                break;
            case "today":
                var start_date = moment(new Date()).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }
                var showData = moment(new Date()).format("DD-MMM-YYYY")
                setReportRequestDate(showData)
                break;
            case "yesterday":
                var date = moment(new Date()).subtract(1, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(date).format("YYYY-MM-DD")
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }
                var showData = moment(date).format("DD-MMM-YYYY")
                setReportRequestDate(showData)
                break;
            case "this_week":
                var week_start_date = moment().startOf('week').format("YYYY-MM-DD")
                var week_end_date = moment(new Date()).format("YYYY-MM-DD")

                var start_date = moment(week_start_date).format("YYYY-MM-DD")
                var end_date = week_end_date
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }

                var showData = (moment(week_start_date).format("DD-MMM-YYYY")) + " - " + (moment(week_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)
                break;
            case "last_7_days":
                var date = moment(new Date()).subtract(7, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }

                var showData = (moment(date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_week":
                var date = moment(new Date()).subtract(1, "week").toDate()
                var week_start_date = moment(date).startOf('week').format("YYYY-MM-DD")
                var week_end_date = moment(date).endOf('week').format("YYYY-MM-DD")

                var start_date = moment(week_start_date).format("YYYY-MM-DD")
                var end_date = moment(week_end_date).format("YYYY-MM-DD")
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }

                var showData = (moment(week_start_date).format("DD-MMM-YYYY")) + " - " + (moment(week_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_14_days":
                var date = moment(new Date()).subtract(14, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }

                var showData = (moment(date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "this_month":
                var month_start_date = moment().startOf('month').format("YYYY-MM-DD")
                var month_end_date = moment(new Date()).format("YYYY-MM-DD")

                var start_date = moment(month_start_date).format("YYYY-MM-DD")
                var end_date = month_end_date
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }

                var showData = (moment(month_start_date).format("DD-MMM-YYYY")) + " - " + (moment(month_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_30_days":
                var date = moment(new Date()).subtract(30, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }

                var showData = (moment(date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_month":
                var date = moment(new Date()).subtract(1, "month").toDate()
                var month_start_date = moment(date).startOf('month').format("YYYY-MM-DD")
                var month_end_date = moment(date).endOf('month').format("YYYY-MM-DD")

                var start_date = moment(month_start_date).format("YYYY-MM-DD")
                var end_date = moment(month_end_date).format("YYYY-MM-DD")
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }

                var showData = (moment(month_start_date).format("DD-MMM-YYYY")) + " - " + (moment(month_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "this_year":
                var year_start_date = moment().startOf('year').format("YYYY-MM-DD")
                var year_end_date = moment(new Date()).format("YYYY-MM-DD")

                var start_date = moment(year_start_date).format("YYYY-MM-DD")
                var end_date = year_end_date
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }

                var showData = (moment(year_start_date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_year":
                var date = moment(new Date()).subtract(1, "year").toDate()
                var year_start_date = moment(date).startOf('year').format("YYYY-MM-DD")
                var year_end_date = moment(date).endOf('year').format("YYYY-MM-DD")

                var start_date = moment(year_start_date).format("YYYY-MM-DD")
                var end_date = moment(year_end_date).format("YYYY-MM-DD")
                getLeadsSummaryData = { employee_id: employee_id, start_date: start_date, end_date: end_date }

                var showData = (moment(year_start_date).format("DD-MMM-YYYY")) + " - " + (moment(year_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            default:
                alert("Error")
                setReportRequestDate("")
                break;
        }


        context_data.setProcessing(true)

        const getLeadsSummaryUrl = global.config.server_url + "getLeadsSummary";
        await axios.post(getLeadsSummaryUrl, getLeadsSummaryData).then((getLeadsSummaryResponse) => {
            if (getLeadsSummaryResponse.data) {
                setLeadsSummaryData(getLeadsSummaryResponse.data);
                var sumTotal = 0;
                var sumIncoming = 0;
                var sumWarm = 0;
                var sumProspect = 0;
                var sumBookingConfirmed = 0;
                var sumClosed = 0;

                var sumFollowupCreate = 0;
                var sumFollowupConducted = 0;
                var sumFollowupCancelled = 0;
                var sumSitevisitCreate = 0;
                var sumSitevisitConducted = 0;
                var sumSitevisitCancelled = 0;
                var sumCallCreate = 0;
                var sumCallDuration = 0;

                for (let i = 0; i < getLeadsSummaryResponse.data.length; i++) {
                    const element = getLeadsSummaryResponse.data[i];
                    sumTotal = sumTotal + element.Total;
                    sumIncoming = sumIncoming + element.Incoming;
                    sumWarm = sumWarm + element.Warm;
                    sumProspect = sumProspect + element.Prospect;
                    sumBookingConfirmed = sumBookingConfirmed + element.BookingConfirmed;
                    sumClosed = sumClosed + element.Closed;

                    sumFollowupCreate = sumFollowupCreate + element.followup_create;
                    sumFollowupConducted = sumFollowupConducted + element.followup_conducted;
                    sumFollowupCancelled = sumFollowupCancelled + element.followup_cancelled;
                    sumSitevisitCreate = sumSitevisitCreate + element.sitevisit_create;
                    sumSitevisitConducted = sumSitevisitConducted + element.sitevisit_conducted;
                    sumSitevisitCancelled = sumSitevisitCancelled + element.sitevisit_cancelled;
                    sumCallCreate = sumCallCreate + element.call_create;
                    sumCallDuration = sumCallDuration + element.call_duration
                }

                setSumLeadsSummaryData({
                    sumProject: getLeadsSummaryResponse.data.length,
                    sumTotal: sumTotal,
                    sumIncoming: sumIncoming,
                    sumWarm: sumWarm,
                    sumProspect: sumProspect,
                    sumBookingConfirmed: sumBookingConfirmed,
                    sumClosed: sumClosed,
                    sumFollowupCreate: sumFollowupCreate,
                    sumFollowupConducted: sumFollowupConducted,
                    sumFollowupCancelled: sumFollowupCancelled,
                    sumSitevisitCreate: sumSitevisitCreate,
                    sumSitevisitConducted: sumSitevisitConducted,
                    sumSitevisitCancelled: sumSitevisitCancelled,
                    sumCallCreate: sumCallCreate,
                    sumCallDuration: sumCallDuration,
                })

                context_data.setProcessing(false)
            } else {
                // alert("error in getting data")
                setLeadsSummaryData(getLeadsSummaryResponse.data);
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });

    }

    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            sortable: true,
            autoHeight: true,
        }
    )

    const ucwords_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = (params.data[column_name]) ? ucwords(params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
        return newValue
    }

    const uppercase_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = params.data[column_name] ? (params.data[column_name].toUpperCase()).replaceAll('_', ' ') : "-"
        return newValue
    }

    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    function createSumData() {
        var result = [{
            serial_number: "Total",
            project_name: "_" + sumLeadsSummaryData.sumProject,
            builder_name: "_" + sumLeadsSummaryData.sumProject,
            Total: sumLeadsSummaryData.sumTotal,
            Incoming: sumLeadsSummaryData.sumIncoming,
            Warm: sumLeadsSummaryData.sumWarm,
            Prospect: sumLeadsSummaryData.sumProspect,
            BookingConfirmed: sumLeadsSummaryData.sumBookingConfirmed,
            Closed: sumLeadsSummaryData.sumClosed,

            followup_create: sumLeadsSummaryData.sumFollowupCreate,
            followup_conducted: sumLeadsSummaryData.sumFollowupConducted,
            followup_cancelled: sumLeadsSummaryData.sumFollowupCancelled,
            sitevisit_create: sumLeadsSummaryData.sumSitevisitCreate,
            sitevisit_conducted: sumLeadsSummaryData.sumSitevisitConducted,
            sitevisit_cancelled: sumLeadsSummaryData.sumSitevisitCancelled,
            call_create: sumLeadsSummaryData.sumCallCreate,
            call_duration: sumLeadsSummaryData.sumCallDuration,

        }];
        return result;
    }

    const cellClassRules = {
        "alert alert-primary table_sticky_row": params => ((params.node.rowPinned))
    };

    const handleSelectChange = () => {
        document.getElementById("btn_form_submit").click()
    }

    const exportAsImage = async (element, imageFileName) => {
        document.getElementById("div_leads_summary_list").style.display = "block"
        const html = document.getElementsByTagName("html")[0];
        const body = document.getElementsByTagName("body")[0];
        let htmlWidth = html.clientWidth;
        let bodyWidth = body.clientWidth;
        const newWidth = element.scrollWidth - element.clientWidth;
        if (newWidth > element.clientWidth) {
            htmlWidth += newWidth;
            bodyWidth += newWidth;
        }
        html.style.width = htmlWidth + "px";
        body.style.width = bodyWidth + "px";
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
        html.style.width = null;
        body.style.width = null;
        document.getElementById("div_leads_summary_list").style.display = "none"
    };

    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;

        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);

        fakeLink.remove();
    };

    const call_duration_value = (params) => {
        var column_name = (params.column.colId)
        console.log(params.data[column_name])
        var newValue = (params.data[column_name]) ? msToReadableTime(params.data[column_name]) : "-"

        return newValue
    }

    function msToReadableTime(time) {
        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        // const day = hour * 24;

        // let days = Math.floor(time / day);
        // let hours = Math.floor(time / hour % 24);
        let hours = Math.floor(time / hour);
        let minutes = Math.floor(time / minute % 60);
        let seconds = Math.floor(time / second % 60);

        // var str_days = days ? days + (days > 1 ? " Days, " : " Day, ") : "";
        var str_hours = hours ? hours + (hours > 1 ? " Hours, " : " Hour, ") : "";
        var str_minutes = minutes ? minutes + (minutes > 1 ? " Minutes, " : " Minute, ") : "";
        var str_seconds = seconds ? seconds + (seconds > 1 ? " Seconds" : " Second") : "";

        return str_hours + str_minutes + str_seconds;


    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <h3 className='text-center'>Leads Summary{(reportRequestDate) ? " : " + reportRequestDate : ""}</h3>
                </div>
            </div>

            <form onSubmit={getLeadsSummary} id="form_aaa">
                <div className="row">
                    {(localStorage.getItem("employee_type") == "MASTERUSER") ? (
                        (employeesData == false || employeesData == null) ? ("No Employee Available") : (
                            <div className="col-md-3">
                                <FloatingLabel controlId="floatingSelect" label="Employee">
                                    <Form.Select name="employee" id="select_employee" aria-label="Floating label select example"
                                        onChange={handleSelectChange}>
                                        <option value="" selected>Select Employee</option>
                                        {employeesData.map((employee) => <option key={employee.id} value={employee.employee_id}>{ucwords(employee.employee_name.toLowerCase())}</option>)}
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                        )
                    ) :
                        <input type="hidden" name="employee" value={loginData.employee_id ? loginData.employee_id : localStorage.getItem("employee_id")} />
                    }

                    <div className="col-md-3">
                        <FloatingLabel controlId="floatingSelect" label="View">
                            <Form.Select name="view_type" id="select_view_type" aria-label="Floating label select view"
                                onChange={handleSelectChange}>
                                <option value="all_data" selected>All Data</option>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="this_week">This Week</option>
                                <option value="last_7_days">Last 7 days</option>
                                <option value="last_week">Last Week</option>
                                <option value="last_14_days">Last 14 days</option>
                                <option value="this_month">This Month</option>
                                <option value="last_30_days">Last 30 days</option>
                                <option value="last_month">Last Month</option>
                                <option value="this_year">This Year</option>
                                <option value="last_year">Last Year</option>
                            </Form.Select>
                        </FloatingLabel>
                    </div>
                    <input type="submit" value="submit" id="btn_form_submit" style={{ display: "none" }} />
                    <div className="col-md-3"></div>
                    <div className="col-md-3 text-end">
                        <button className='btn btn-secondary' onClick={() => exportAsImage(exportRef.current, ("reports_" + moment(new Date()).format("YYYYMMDDHHmmss") + "_" + (selectedEmployeeId ? selectedEmployeeId : localStorage.getItem("employee_id"))))}>
                            Capture Image
                        </button>
                    </div>
                </div>
            </form>

            <div className="row">
                <div className="col-12">
                    {(leadsSummaryData && sumLeadsSummaryData) ? (
                        <>
                            {console.log(leadsSummaryData)}
                            <div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
                                <AgGridReact
                                    pinnedTopRowData={createSumData()}
                                    defaultColDef={defaultColDef}
                                    rowData={leadsSummaryData}
                                    pagination={true}
                                    paginationAutoPageSize={true}
                                    animateRows={true}
                                    ref={gridRef}
                                    onGridReady={() => autoSizeAll(false)}
                                    onFirstDataRendered={() => autoSizeAll(false)}
                                >
                                    <AgGridColumn width="100" headerName="Sl No" field="serial_number" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Project" field="project_id" valueGetter={ucwords_value} cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Project" field="project_name" valueGetter={ucwords_value} cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Builder" field="builder_name" valueGetter={ucwords_value} cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Total" field="Total" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Incoming" field="Incoming" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Warm" field="Warm" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Prospect" field="Prospect" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Booking Confirmed" field="BookingConfirmed" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Closed" field="Closed" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Followup Create" field="followup_create" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Followup Conducted" field="followup_conducted" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Followup Cancelled" field="followup_cancelled" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Sitevisit Create" field="sitevisit_create" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Sitevisit Conducted" field="sitevisit_conducted" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Sitevisit Cancelled" field="sitevisit_cancelled" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Call Create" field="call_create" cellClassRules={cellClassRules} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Call Duration" field="call_duration" cellClassRules={cellClassRules} valueGetter={call_duration_value} ></AgGridColumn>
                                </AgGridReact>
                            </div>
                            <div className="div_leads_summary_list" id="div_leads_summary_list">
                                <table id="all_leads_summary_list" className="display table table-striped table-bordered " ref={exportRef} >
                                    <thead className="bg-dark text-white">
                                        <tr>
                                            <th colSpan={"16"} className="text-center">Leads Summary{(reportRequestDate) ? " : " + reportRequestDate : ""}</th>
                                        </tr>
                                        <tr>
                                            <th className='leads_summary_list_th'>#</th>
                                            <th className='leads_summary_list_th'>Project Name</th>
                                            <th className='leads_summary_list_th'>Builder Name</th>
                                            <th className='leads_summary_list_th'>Total</th>
                                            <th className='leads_summary_list_th'>Incoming</th>
                                            <th className='leads_summary_list_th'>Warm</th>
                                            <th className='leads_summary_list_th'>Prospect</th>
                                            <th className='leads_summary_list_th'>Booking Confirmed</th>
                                            <th className='leads_summary_list_th'>Closed</th>
                                            <th className='leads_summary_list_th'>Followup Create</th>
                                            <th className='leads_summary_list_th'>Followup Conducted</th>
                                            <th className='leads_summary_list_th'>Followup Cancelled</th>
                                            <th className='leads_summary_list_th'>Sitevisit Create</th>
                                            <th className='leads_summary_list_th'>Sitevisit Conducted</th>
                                            <th className='leads_summary_list_th'>Sitevisit Cancelled</th>
                                            <th className='leads_summary_list_th'>Call Create</th>
                                            <th className='leads_summary_list_th'>Call Duration</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={"total"} >
                                            <td className="leads_summary_list_td_total">Total</td>
                                            <td className="leads_summary_list_td_total">{sumLeadsSummaryData.sumProject}</td>
                                            <td className="leads_summary_list_td_total">{sumLeadsSummaryData.sumProject}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumTotal}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumIncoming}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumWarm}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumProspect}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumBookingConfirmed}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumClosed}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumFollowupCreate}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumFollowupConducted}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumFollowupCancelled}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumSitevisitCreate}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumSitevisitConducted}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumSitevisitCancelled}</td>
                                            <td className="leads_summary_list_td_total text-end">{sumLeadsSummaryData.sumCallCreate}</td>
                                            <td className="leads_summary_list_td_total text-end">{msToReadableTime(sumLeadsSummaryData.sumCallDuration)}</td>
                                        </tr>

                                        {leadsSummaryData.map((leadsSummary, index) =>
                                            <tr key={leadsSummary.id} >
                                                <td className="leads_summary_list_td">{index + 1}</td>
                                                <td className="leads_summary_list_td">{(leadsSummary.project_name) ? ucwords(leadsSummary.project_name.toLowerCase()) : "-"}</td>
                                                <td className="leads_summary_list_td">{(leadsSummary.builder_name) ? ucwords(leadsSummary.builder_name.toLowerCase()) : "-"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.Total) ? leadsSummary.Total : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.Incoming) ? leadsSummary.Incoming : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.Warm) ? leadsSummary.Warm : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.Prospect) ? leadsSummary.Prospect : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.BookingConfirmed) ? leadsSummary.BookingConfirmed : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.Closed) ? leadsSummary.Closed : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.followup_create) ? leadsSummary.followup_create : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.followup_conducted) ? leadsSummary.followup_conducted : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.followup_cancelled) ? leadsSummary.followup_cancelled : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.sitevisit_create) ? leadsSummary.sitevisit_create : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.sitevisit_conducted) ? leadsSummary.sitevisit_conducted : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.sitevisit_cancelled) ? leadsSummary.sitevisit_cancelled : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.call_create) ? leadsSummary.call_create : "0"}</td>
                                                <td className="leads_summary_list_td text-end">{(leadsSummary.call_duration) ? msToReadableTime(leadsSummary.call_duration) : "0"}</td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (<div><h3 className="text-center m-5">No Data Available</h3></div>)}

                </div>
            </div>
        </div >
    )
}

export default LeadsSummary